import React from "react";

import Others from "./Others";
import Pages from "./Pages";
import Login from "./Login";
import LandingPage from "./Landing";

const currentPage = window.location.pathname;

const layout = (WrappedComponent, type) => (props) => {
  switch (type) {
    case "login":
      return (
        <Login pathName={currentPage}>
          <WrappedComponent {...props} />
        </Login>
      );
    case "others":
      return (
        <Others>
          <WrappedComponent {...props} />
        </Others>
      );
    case "landing":
      return (
        <LandingPage>
          <WrappedComponent {...props} />
        </LandingPage>
      );
    default:
      return (
        <Pages>
          <WrappedComponent {...props} />
        </Pages>
      );
  }
};

export default layout;
