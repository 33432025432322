import React from "react";
import Styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

import { AuthProvider } from "../../server-requests/authContextProvider";
import UpdateDisplayName from "./UpdateDisplayName";

const AccountInformation = () => {
  const addEditDisplayNameRef = React.useRef();
  const { firebaseUser } = React.useContext(AuthProvider);

  const updateDisplayNameClickHandler = () =>
    addEditDisplayNameRef.current.Open();

  let displayName = (
    <li>
      <b>Nome</b>:{" "}
      <span onClick={() => updateDisplayNameClickHandler("add")}>
        Adicionar nome de exibição?
      </span>
    </li>
  );
  if (firebaseUser.displayName)
    displayName = (
      <li>
        <b>Nome</b>: {firebaseUser.displayName}
        <EditIcon
          className="editProfile"
          onClick={() => updateDisplayNameClickHandler()}
        />
      </li>
    );

  return (
    <AccountInformationStyled>
      {displayName}
      <li>
        <b>Email</b>: {firebaseUser.email}
      </li>
      <UpdateDisplayName ref={addEditDisplayNameRef} />
    </AccountInformationStyled>
  );
};

export default AccountInformation;

const AccountInformationStyled = Styled.ul`
  ${(props) => {
    let theme = props.theme;
    return `
      padding:${theme.gLG};
      list-style:none;
      >li{
          display:flex;
          line-height:30px;
          border-bottom:1px solid rgba(${theme.black}, 0.02);
          margin-bottom:${theme.gSM};
         
            >span{
              color:rgba(${theme.primary});
              margin-left:5px;
              cursor:pointer;
              :hover{
                color:rgba(${theme.black});
              }
            }
            .editProfile{
              margin-left:auto;
              width:18px;
              color:rgba(${theme.black},0.4);
              cursor:pointer;
              :hover{
                color:rgba(${theme.primary});
              }
            }
      }
      
    `;
  }}
`;
