import React from "react";

import { HeadlineOne } from "../../theme";
import { AuthProvider } from "../../server-requests/authContextProvider";
import { auth } from "../../firebase";
import { sendEmailVerification } from "@firebase/auth";
import UseButton from "../shared/useButton";

const EmailVerification = () => {
  const { firebaseUser, snackbar } = React.useContext(AuthProvider);

  // Resend verification email click handler
  const resendVerificationEmailHandler = () => {
    sendEmailVerification(auth.currentUser).then(() =>
      snackbar(
        `Foi enviado um e-mail para ${firebaseUser.email}.`,
        "success",
        "logout"
      )
    );
  };
  return (
    <>
      <HeadlineOne center margin="gXL">
        Conta inativa
      </HeadlineOne>
      <p style={{ marginBottom: 10 }}>
        O seu correio electrónico <b>({firebaseUser && firebaseUser.email})</b>{" "}
        ainda não foi verificado. Clique em "Enviar" para receber o email de
        verificação.
      </p>
      <UseButton
        clicked={resendVerificationEmailHandler}
        value="Enviar"
        type="button"
        skin={"primary"}
        size="md"
        display="full"
        style={{ marginLeft: "auto" }}
      />
    </>
  );
};

export default EmailVerification;
