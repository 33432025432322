import React from "react";

import Loader from "./Loader";

const Loading = ({ height = "100%" }) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        height: height,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader size="40" thickness="4" dark />
      <h1 style={{ marginLeft: 20 }}>Por favor aguarde...</h1>
    </div>
  );
};

export const LoadingComponent = ({ height = "100%" }) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        height: height,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader size="40" thickness="4" dark />
    </div>
  );
};

export default Loading;
