import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { currentTheme, GlobalStyle } from "./theme";
import AuthContext from "./server-requests/authContextProvider";
import PrivateRoute from "./components/shared/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Reports from "./components/reports/Reports";
import Profile from "./components/profile/Profile";
import Index from "./components/index/Index";
import AccountVerified from "./components/index/accountVerified";
import Login from "./components/login/Login";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import Download from "./components/index/download";

const App = () => {
	return (
		<ThemeProvider theme={currentTheme}>
			<AuthContext>
				<Routes>
					<Route path="/" exact element={<Index />} />
					<Route path="/download" exact element={<Download />} />
					<Route path="/login" exact element={<Login />} />
					<Route path="/account-verified" exact element={<AccountVerified />} />
					<Route path="/forgot-password" exact element={<ForgotPassword />} />
					<Route
						path="/dashboard"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					<Route
						path="/reports"
						element={
							<PrivateRoute>
								<Reports />
							</PrivateRoute>
						}
					/>
					<Route
						path="/profile"
						element={
							<PrivateRoute>
								<Profile />
							</PrivateRoute>
						}
					/>
					<Route element={<PageNotFound />} />
				</Routes>
			</AuthContext>
			<GlobalStyle theme={currentTheme} />
		</ThemeProvider>
	);
};

export default App;
