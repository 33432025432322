import React from "react";
import Styled from "styled-components";

import layout from "../../layout/layout";
import Branding from "../shared/branding";

const Index = () => {
  return (
    <>
      <BannerStyled>
        <Branding logoHeight={140} branding="logo" />
        <Branding branding="name" />
      </BannerStyled>
      <BodyStyled>
        <div className="wrapper">
          <h2>
            QRKoin
            <span>A sua solução de pagamento cashless</span>
          </h2>
          <div className="bodyContent">
            <div className="left">
              <h3>
                Após selecionados os produtos a adquirir, são oferecidas duas
                opções de pagamento:
              </h3>
              <ul>
                <li>Descontando do saldo existente na conta</li>
              </ul>
              <h3 style={{ marginTop: 20 }}>
                Na aplicação terá acesso a informações como:
              </h3>
              <ul>
                <li>O que comprou</li>
                <li>Onde comprou</li>
                <li>Quanto gastou</li>
                <li>Saldo disponível</li>
              </ul>
            </div>
            <div className="right">
              <p>
                A QRKoin é uma aplicação que permite realizar pagamentos
                cashless, em locais como máquinas de venda automática, lavagem
                de automóveis, lavandarias self-service ou qualquer outra
                máquina de serviços automáticos sem atendimento. Na prática
                permite a substituição de um moedeiro ou noteiro apenas por um
                código QR que identifica a máquina.
              </p>
              <p>
                É totalmente gratuita para o utilizador e já está disponível
                para Android e iOS.
              </p>
              <p>
                Para proceder à compra, o utilizador apenas necessita de fazer a
                leitura do código QR existente na máquina de venda, tendo acesso
                a um menu com os artigos disponíveis na mesma. Todos os artigos
                poderão ser definidos numa plataforma de gestão através de
                qualquer web browser.
              </p>
              <p>
                Para as empresas que gerem as máquina são disponibilizados,
                através da plataforma HEXA, um conjunto de indicadores de gestão
                operacional e económicos online.
              </p>
              <p>
                A comunicação entre a máquina e o servidor é feito através de um
                dispositivo IoT. A instalação deste dispositivo é muito simples,
                e permite a comunicação bidirecional entre o equipamento e o
                servidor.
              </p>
              <p>
                Além da funcionalidade de pagamento cashless, a QRKoin permite
                também na sua plataforma criar relatórios de manutenção. Permite
                otimizar rotas técnicas e de reposição, reportar o estado da
                máquina, necessidade de manutenção preventiva ou periódica,
                reposições de que produtos e quantidades em falta, valor de
                vendas totais e por produto, quantidade de moedas na máquina,
                repartição de vendas em numerário, cartão debito ou cartão
                refeição e por QRKoin, etc.
              </p>
              <p>
                É um sistema de gestão configurável e adaptável a qualquer
                cliente, com a possibilidade de configuração dos documentos a
                produzir e integrável com outros softwares de gestão já em
                utilização pelo cliente.
              </p>
            </div>
          </div>
        </div>
      </BodyStyled>
    </>
  );
};

export default layout(Index, "landing");

const BannerStyled = Styled.div`
  ${() => {
    return `
      padding:250px 0;
    `;
  }}
`;

const BodyStyled = Styled.div`
  ${(props) => {
    let theme = props.theme;
    return `
      padding:50px 0;
      background:rgba(${theme.black},0.04);
      h2{
        ${theme.fontPrimary};
        ${theme.fLG};
        margin-bottom:50px;
        text-align:center;
        >span{
          font-style: italic;
          ${theme.fDF};
          display:block;
          margin-top:${theme.gSM};
          font-weight:normal;
        }
      }
      .bodyContent{
        display:flex;
        width: 100%;
        p{
          padding:0 0 ${theme.gLG} 0;
          text-align: justify;
        }
        ul{
          list-style: decimal inside;
          li{
            padding:${theme.gSM} 0 ${theme.gSM} ${theme.gXL};
          }
        }
        h3{
          margin-bottom:${theme.gMD};
          color:rgba(${theme.primary});
        }
        >div.left{
          width:calc(33% - 10px);
        }
        >div.right{
          width:calc(67% - 10px);
          margin-left:auto;
        }
        ${theme.mMX_MD} {
          flex-direction:column;
          >div.left{
            width:100%;
          }
          >div.right{
            margin-top:${theme.gLG};
            width:100%;
            margin-left:unset;
          }
        }
      }
      .wrapper{
        width:1050px;
        margin:0 auto;
      }
      ${theme.mMX_LG} {
        .wrapper{
          width:90%;
        }
      }
    `;
  }}
`;
