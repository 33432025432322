import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { updateProfile } from "@firebase/auth";

import { auth } from "../../firebase";
import {
  getError,
  updateInputChangeSingle,
  updateObject,
} from "../shared/Utility";
import { AuthProvider } from "../../server-requests/authContextProvider";
import UseButton from "../shared/useButton";
import { DialogBody } from "../shared/useDialog";
import UseInput from "../shared/useInput";

const UpdateDisplayName = React.forwardRef((props, ref) => {
  const { setAuthLoading, snackbar } = React.useContext(AuthProvider);
  const [data, setData] = React.useState({
    isOpen: false,
  });

  const closeDialog = () => {
    setData((prev) =>
      updateObject(prev, {
        isOpen: false,
      })
    );
  };

  const [formData, setFormData] = React.useState({
    displayName: {
      label: "Nome",
      validation: {
        required: true,
        min: 6,
        max: 30,
      },
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "text",
        onChange: (e) => inputChangeHandler(e, "displayName"),
      },
    },
    submit: false,
  });

  // in case of add edit and delete
  React.useImperativeHandle(ref, () => ({
    Open() {
      setFormData((prev) => {
        let obj = updateObject(prev, {
          displayName: updateObject(prev.displayName, {
            value: auth.currentUser.displayName
              ? auth.currentUser.displayName
              : "",
            valid: true,
          }),
        });

        return obj;
      });

      setData((prev) => {
        let obj = updateObject(prev, {
          isOpen: true,
        });
        return obj;
      });
    },
  }));

  const inputChangeHandler = (value, key) =>
    setFormData((prev) => updateInputChangeSingle(prev, value, key));

  const submitClickHandler = () => {
    updateProfile(auth.currentUser, {
      displayName: formData.displayName.value,
    })
      .then(() => {
        setAuthLoading(true);
        closeDialog();
      })
      .catch(() => {
        closeDialog();
        snackbar(getError(500));
      });
  };

  if (!data.isOpen) return null;

  let buttonSkin = "secondary";
  if (!formData.submit) buttonSkin = "disable";

  return (
    <DialogBody
      title={auth.currentUser.displayName ? "Editar nome" : "Adicionar nome"}
      size="sm"
      type={data.type}
      close={closeDialog}
    >
      <div className="body">
        <UseInput config={formData.displayName} />
      </div>
      <div className="footer">
        <UseButton
          clicked={closeDialog}
          value="Fechar"
          type="button"
          skin="default"
          size="md"
          icons={{ icon: <CloseIcon /> }}
        />

        <UseButton
          clicked={submitClickHandler}
          value={auth.currentUser.displayName ? "Guardar" : "Adicionar"}
          type="button"
          skin={buttonSkin}
          size="md"
          style={{ marginLeft: "auto" }}
          icons={{
            icon: auth.currentUser.displayName ? <SaveIcon /> : <AddIcon />,
          }}
        />
      </div>
    </DialogBody>
  );
});

export default UpdateDisplayName;
