import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import { allHeight } from "../../theme";
import { AuthProvider } from "../../server-requests/authContextProvider";

const Navigation = (props) => {
  const { firebaseUser } = React.useContext(AuthProvider);
  return (
    <NavigationStyled isOpen={props.isOpen} isWhite={props.isWhite}>
      {firebaseUser ? (
        <>
          <NavLink to={"/dashboard"}>
            <DashboardIcon />
            Página inicial
          </NavLink>
          {!props.isLanding && (
            <NavLink to={"/reports"}>
              <TrendingUpIcon /> Relatório de venda
            </NavLink>
          )}
        </>
      ) : (
        <>
          <NavLink to={"/login"}>
            <LockOpenIcon />
            Entrar
          </NavLink>
        </>
      )}
    </NavigationStyled>
  );
};
export default Navigation;
const NavigationStyled = styled.div`
  ${(props) => {
    let theme = props.theme;
    return `
    z-index: 11;
    display:flex;
    margin-left:auto;
    > a {
      color: rgb(${theme.secondary});
      padding: 0 ${theme.gMD};
      ${theme.secondaryFont};
      ${theme.fDF};
      display:flex;
      align-items: center;
      :hover {
        color: rgb(${theme.black}, 1);
      }
      > svg {
        color: rgb(${theme.secondary});
        margin-right: ${theme.gSM};
      }
    }
    > a.active {
      color: rgb(${theme.black}, 1);
      > svg {
        color: rgb(${theme.primary});
        margin-right: ${theme.gSM};
      }
    }
    ${theme.mMX_MD} {
      position: absolute;
      left: -250px;
      top: 0;
      height: 100%;
      width: 250px;
      background: rgb(${theme.primary});
      padding-top: ${allHeight}px;
      transition: 0.2s;
      display:none;
      > a {
        display: block;
        padding: ${theme.gLG};
        color: rgb(${theme.white});
        :hover {
          background: rgb(${theme.white});
          color: rgb(${theme.primary});
        }
      }
      >a.active {
        background: rgb(${theme.white});
        color: rgb(${theme.primary});
      }
    }
    ${
      props.isOpen &&
      `
        display:flex!important;
        left: 0!important;
        flex-direction: column;
        justify-content: flex-start!important;
        >a{
          align-items: center;
          display: flex;
        }
    `
    }
  `;
  }}
`;
