import React from "react";

import Footer from "../components/shared/Footer";
import { RootWrapper, OuterBody, InnerBody, OutWrapper } from "../theme";
import Header from "../components/shared/HeaderLanding";
import { AuthProvider } from "../server-requests/authContextProvider";
import Loading from "../components/shared/Loading";

const LandingPage = (props) => {
  const { authLoading } = React.useContext(AuthProvider);
  if (authLoading) {
    return <Loading height={"100vh"} />;
  } else {
    return (
      <OutWrapper>
        <RootWrapper>
          <Header isWhite isLanding />
          <OuterBody fixedFooter hidden>
            <InnerBody npPadding fixedFooter>
              {props.children}
            </InnerBody>
            <Footer isLanding={true} />
          </OuterBody>
        </RootWrapper>
      </OutWrapper>
    );
  }
};

export default LandingPage;
