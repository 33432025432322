import React from "react";
import { AuthProvider } from "../../server-requests/authContextProvider";
import UseButton from "../shared/useButton";
import UseInput from "../shared/useInput";
import {
  getError,
  updateInputChangeSingle,
  updateObject,
} from "../shared/Utility";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { Put } from "../../server-requests/serverRequest";
import { DialogBody } from "../shared/useDialog";

const EditPrice = React.forwardRef(({ success }, ref) => {
  const { snackbar } = React.useContext(AuthProvider);
  const [data, setData] = React.useState({
    isOpen: false,
    type: "add",
    current: null,
    deviceId: null,
  });
  const closeDialog = () => {
    resetValue();
    setData((prev) =>
      updateObject(prev, {
        isOpen: false,
      })
    );
  };

  const [formData, setFormData] = React.useState({
    amount: {
      label: "Preço",
      validation: {
        required: true,
        minVal: 0,
        maxVal: 999.99,
      },
      valid: false,
      touched: false,
      className: "marginBottom",
      value: "",
      element: {
        type: "number",
        placeholder: "1.00€",
        onChange: (e) => inputChangeHandler(e, "amount"),
      },
    },
    pulses: {
      label: "Impulsos",
      validation: {
        required: true,
        minVal: 0,
        maxVal: 99,
      },
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "number",
        onChange: (e) => inputChangeHandler(e, "pulses"),
      },
    },
    submit: false,
  });

  // in case of add edit and delete
  React.useImperativeHandle(ref, () => ({
    Open(current, deviceId) {
      if (current && deviceId) {
        setFormData((prev) => {
          let obj = updateObject(prev, {
            amount: updateObject(prev.amount, {
              value: (current.amount / 100).toString(),
              valid: true,
            }),
            pulses: updateObject(prev.pulses, {
              value: current.pulses,
              valid: true,
            }),
          });
          return obj;
        });
      }
      setData((prev) => {
        let obj = updateObject(prev, {
          isOpen: true,
        });
        if (current) obj.current = current;
        if (deviceId) obj.deviceId = deviceId;
        return obj;
      });
    },
  }));

  const inputChangeHandler = (value, key) =>
    setFormData((prev) => updateInputChangeSingle(prev, value, key));

  // set as default on close popup
  const resetValue = () => {
    setFormData((prev) =>
      updateObject(prev, {
        amount: updateObject(prev.amount, {
          valid: false,
          touched: false,
          value: "",
        }),
        pulses: updateObject(prev.pulses, {
          valid: false,
          touched: false,
          value: "",
        }),
      })
    );
  };

  const submitClickHandler = () => {
    Put(
      "prices",
      {
        amount: formData.amount.value * 100,
        pulses: formData.pulses.value,
        deviceId: data.deviceId,
      },
      { id: data.current.id }
    )
      .then(() => {
        closeDialog();
        success();
      })
      .catch((status) => snackbar(getError(status)));
  };

  if (!data.isOpen) return null;

  let titleElement = "Editar preço";

  let buttonSkin = "primary";
  if (data.type === "edit") buttonSkin = "secondary";
  if (data.type === "delete") buttonSkin = "danger";
  if (!formData.submit) buttonSkin = "disable";

  return (
    <DialogBody
      title={titleElement}
      size="sm"
      type={data.type}
      close={closeDialog}
    >
      <div className="body">
        <UseInput config={formData.amount} />
        <UseInput config={formData.pulses} />
      </div>
      <div className="footer">
        <UseButton
          clicked={closeDialog}
          value="Fechar"
          type="button"
          skin="default"
          size="md"
          display="default"
          icons={{ icon: <CloseIcon /> }}
        />

        <UseButton
          clicked={submitClickHandler}
          value="Guardar"
          type="button"
          skin={buttonSkin}
          size="md"
          style={{ marginLeft: "auto" }}
          icons={{ icon: <SaveIcon /> }}
        />
      </div>
    </DialogBody>
  );
});

export default EditPrice;
