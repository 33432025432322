import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import {
  CardWrapperStyled,
  CardStyled,
  BoxStyled,
  CardHeaderStyled,
  CardBodyStyled,
} from "../../theme";
import AccountInformation from "./AccountInformation";
import ChangePassword from "./ChangePassword";
import layout from "../../layout/layout";

const Profile = () => {
  return (
    <CardWrapperStyled center={true}>
      <CardStyled max="500" width="50.1">
        <BoxStyled>
          <CardHeaderStyled>
            <h2>
              <InfoIcon />
              Informação da conta
            </h2>
          </CardHeaderStyled>
          <CardBodyStyled>
            <AccountInformation />
          </CardBodyStyled>
        </BoxStyled>
      </CardStyled>
      <CardStyled max="500" width="50.1" noMargin topMargin>
        <BoxStyled>
          <CardHeaderStyled>
            <h2>
              <LockOpenIcon />
              Alterar palavra-passe
            </h2>
          </CardHeaderStyled>
          <CardBodyStyled>
            <ChangePassword />
          </CardBodyStyled>
        </BoxStyled>
      </CardStyled>
    </CardWrapperStyled>
  );
};

export default layout(Profile);
