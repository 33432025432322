import React from "react";
import { useLocation, Navigate } from "react-router-dom";

import Footer from "../components/shared/Footer";
import { RootWrapper, OuterBody, InnerBody, LoginBoxStyled } from "../theme";
import { AuthProvider } from "../server-requests/authContextProvider";
import Loading from "../components/shared/Loading";
import EmailVerification from "../components/login/EmailVerification";
import Branding from "../components/shared/branding";

const Login = ({ children }) => {
  const { user, authLoading, isVerified } = React.useContext(AuthProvider);
  const { state } = useLocation();

  if (authLoading) return <Loading height={"100vh"} />;
  if (user && isVerified) {
    return <Navigate to={state ? state.from : "/dashboard"} />;
  }
  return (
    <RootWrapper>
      <OuterBody login>
        <InnerBody>
          <Branding />
          <LoginBoxStyled>
            {!isVerified ? <EmailVerification /> : children}
          </LoginBoxStyled>
          <Footer login />
        </InnerBody>
      </OuterBody>
    </RootWrapper>
  );
};

export default Login;
