import { initializeApp } from "firebase/app"
import {
	getAuth,
	updatePassword,
	reauthenticateWithCredential,
	EmailAuthProvider,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	getIdToken,
	signOut,
} from "firebase/auth"

import { Log } from "./components/shared/Utility"

const firebaseApp = {
	apiKey: process.env.REACT_APP_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PRODUCT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_ID,
}
const Firebase = initializeApp(firebaseApp)
export const auth = getAuth()

export const SignIn = (email, password) => {
	return new Promise((resolve, reject) => {
		signInWithEmailAndPassword(auth, email, password)
			.then((user) => {
				Log(`Successfully signing in: ${JSON.stringify(user.user, null, 1)}`)
				resolve(user)
			})
			.catch((error) => {
				Log(`Error while sign up:`, error)
				switch (error.code) {
					case "auth/invalid-email":
						reject(400)
						break
					case "auth/wrong-password":
						reject(401)
						break
					case "auth/user-not-found":
						reject(401)
						break
					default:
						reject(500)
						break
				}
			})
	})
}

export const SignOutUser = () => {
	return new Promise((resolve, reject) => {
		signOut(auth)
			.then(() => {
				resolve()
				Log(`Success: signOut--firebase.js`)
			})
			.catch((error) => {
				Log(`Error: signOut--firebase.js`, error)
				reject(error)
			})
	})
}

export const SendPasswordResetEmail = (email) => {
	return new Promise((resolve, reject) => {
		sendPasswordResetEmail(auth, email)
			.then(() => {
				Log("Reset password email has been send to the:" + email)
				resolve(200)
			})
			.catch((error) => {
				Log("Error reset password", error)
				reject(500)
			})
	})
}

export const FirebaseChangePassword = (email, password, newPassword) => {
	return new Promise((resolve, reject) => {
		if (email && password && newPassword) {
			const credential = EmailAuthProvider.credential(email, password)
			reauthenticateWithCredential(auth.currentUser, credential)
				.then(() => {
					updatePassword(auth.currentUser, newPassword)
						.then(() => {
							Log("Password has been successfully changed.")
							resolve(200)
						})
						.catch((error) => {
							Log("Error on change password", error)
							reject(500)
						})
				})
				.catch((error) => {
					Log("Error on re-authenticate", error)
					reject(error.code === "auth/wrong-password" ? 401 : 500)
				})
		} else {
			Log("Missing email/password/newPassword")
			reject(400)
		}
	})
}

export const getFirebaseToken = () => {
	return new Promise((resolve, reject) => {
		getIdToken(auth.currentUser)
			.then((token) => resolve(token))
			.catch((error) => {
				Log("Error getting firebase token from getIdToken", error)
				reject(error)
			})
	})
}

export default Firebase
