import React from "react";

import { LogoHolderLoginStyled } from "../../theme";
import Logo from "./Logo";

const Branding = ({
  branding = "both",
  logoHeight = 120,
  align = "center",
  className = "",
}) => {
  return (
    <LogoHolderLoginStyled className={className}>
      <Logo
        branding={branding}
        linkTo="/"
        logoHeight={logoHeight}
        align={align}
      />
    </LogoHolderLoginStyled>
  );
};

export default Branding;
