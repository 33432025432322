import React from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";

import Logo from "../shared/Logo";
import { LinkStyled } from "../../theme";
import Footer from "../shared/Footer";

const PageNotFound = () => {
  return (
    <PageNotFoundRoutesStyled>
      <Logo logoHeight={120} align="center" linkTo="/" />
      <ErrorTitle>Erro</ErrorTitle>
      <ErrorText>
        Desculpe, mas a página que você está à procura não existe pois foi
        removida, mudou de nome ou está temporariamente indisponível.
      </ErrorText>
      <LinkStyled>
        <Link to="/">Entrar / Página inicial</Link>
      </LinkStyled>
      <Footer login />
    </PageNotFoundRoutesStyled>
  );
};

export default PageNotFound;

const ErrorText = Styled.p`
  ${(props) => {
    let current = props.theme;
    return `
        padding:${current.gXL};
        margin:0!important;
        max-width:450px;
        text-align:center;
    `;
  }}
`;
const ErrorTitle = Styled.h2`
  ${(props) => {
    let theme = props.theme;
    return `
        ${theme.fXL};
        text-transform: uppercase;
        margin-top:${theme.gXL};
    `;
  }}
`;
const PageNotFoundRoutesStyled = Styled.div`
  ${(props) => {
    return `
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        >a{
            text-align:center;
        }
    `;
  }}
`;
