import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import Footer from "../components/shared/Footer";
import { RootWrapper, OuterBody, InnerBody, OutWrapper } from "../theme";
import Header from "../components/shared/Header";
import { AuthProvider } from "../server-requests/authContextProvider";
import Loading from "../components/shared/Loading";

const Pages = (props) => {
  const { user, authLoading, isVerified } = useContext(AuthProvider);

  if (authLoading) return <Loading height={"100vh"} />;
  if (!user || !isVerified) return <Navigate to="/" />;
  return (
    <OutWrapper>
      <RootWrapper>
        <Header isWhite />
        <OuterBody fixedFooter>
          <InnerBody fixedFooter>{props.children}</InnerBody>
          <Footer />
        </OuterBody>
      </RootWrapper>
    </OutWrapper>
  );
};

export default Pages;
