import React from "react";
import UseButton from "./useButton";
import UseInput from "./useInput";
import { updateObject } from "./Utility";

const FormHooks = ({
  formInputs,
  inputChange,
  hideError = false,
  onSubmit,
  loading,
}) => {
  const formElement = [];
  for (let key in formInputs) {
    formElement.push({
      id: key,
      config: formInputs[key],
    });
  }
  if (formElement.length === 0) return null;
  return (
    <form onSubmit={onSubmit} autoComplete="off" method="post">
      {formElement.map((el) => {
        let skinEl = loading
          ? "disable"
          : el.config.valid
          ? "primary"
          : "disable";
        if (el.id === "submit") {
          return (
            <UseButton
              key={el.id}
              type={el.config.element.type}
              skin={skinEl}
              clicked={el.config.clicked && el.config.clicked()}
              display={el.config.element.display}
              value={el.config.label}
              icons={el.config.icons}
              loading={loading}
            />
          );
        } else {
          let config = updateObject(el.config, {
            element: updateObject(el.config.element, {
              name: el.id,
              onChange: (e) => inputChange(e, el.id),
            }),
          });
          return (
            <UseInput
              key={el.id}
              className={`${
                !hideError && !el.config.valid && el.config.touched
                  ? "error"
                  : ""
              } ${el.config.className ? el.config.className : ""}`}
              config={config}
              ref={el.config.inputRef}
            />
          );
        }
      })}
    </form>
  );
};

export default FormHooks;
