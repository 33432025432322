import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
const Loader = ({ size, thickness, dark }) => {
  return (
    <LoadingStyled dark={dark} size={size}>
      <CircularProgress
        variant="determinate"
        value={100}
        className="top"
        size={Number(size)}
        thickness={Number(thickness)}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={Number(size)}
        className="bottom"
        thickness={Number(thickness)}
      />
    </LoadingStyled>
  );
};

Loader.propTypes = {
  size: PropTypes.string.isRequired,
  thickness: PropTypes.string.isRequired,
  dark: PropTypes.bool,
};

export default Loader;
const LoadingStyled = styled.div`
  ${(props) => {
    let theme = props.theme;
    let loaderColor = props.dark ? theme.primary : theme.white;
    return `
            margin-right:${theme.gSM};
            display:flex;
            align-items:center;
            justify-content:center;
            .top{
                color:rgba(${loaderColor}, 0.3)!important;
            }
            .bottom{
                color:rgb(${loaderColor});
                animation-duration:400ms;
                margin-left:-${props.size}px;
        `;
  }}
`;
