import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from '@mui/icons-material/History';

import { Post } from "../../server-requests/serverRequest";
import { AuthProvider } from "../../server-requests/authContextProvider";
import { formatAmount, getError, updateObject } from "../shared/Utility";
import UseButton from "../shared/useButton";
import { DialogBody } from "../shared/useDialog";

const Refund = React.forwardRef(({ success, reload }, ref) => {
  const { snackbar } = React.useContext(AuthProvider);

  const [data, setData] = React.useState({
    isOpen: false,
    current: null,
    clicked: false,
  });

  const closeDialog = () => {
    setData((prev) =>
      updateObject(prev, {
        isOpen: false,
      })
    );
  };

  // in case of add edit and delete
  React.useImperativeHandle(ref, () => ({
    Open(current) {
      setData((prev) => {
        let obj = updateObject(prev, {
          current: current,
          clicked: false,
          isOpen: true,
        });

        return obj;
      });
    },
  }));

  const submitClickHandler = () => {
    setData((prev) =>
      updateObject(prev, {
        clicked: true,
      })
    );
    Post("movements/refund", { id: data.current.id })
      .then(() => {
        closeDialog();
        success();
      })
      .catch((status) => {
        closeDialog();
        if (status === 405) reload();
        snackbar(getError(status));
      });
  };

  if (!data.isOpen) return null;

  return (
    <DialogBody
      title="Confirmar reembolso?"
      size="sm"
      type={data.type}
      close={closeDialog}
    >
      <div className="body alert">
        <p style={{  margin: "10px 0" }}>
        Confirmar reembolso de {formatAmount(data.current.amount)} para o produto {data.current.description}?
        </p>
      </div>
      <div className="footer">
        <UseButton
          clicked={closeDialog}
          value="Fechar"
          type="button"
          skin="default"
          size="md"
          display="default"
          icons={{ icon: <CloseIcon /> }}
        />

        <UseButton
          clicked={submitClickHandler}
          value="Reembolso"
          type="button"
          skin={data.clicked ? "disable" : "primary"}
          size="md"
          style={{ marginLeft: "auto" }}
          icons={{ icon: <HistoryIcon /> }}
        />
      </div>
    </DialogBody>
  );
});

export default Refund;
