const TIMEZONE_PT = "Europe/Lisbon"
const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss"
const DATE_FORMAT = "yyyy-MM-dd"
const TIME_FORMAT = "HH:mm:ss"

const DateFNS = require("date-fns")
const DateFNSTZ = require("date-fns-tz")

export const shortName = (name) => {
	let shortNameToReturn = ""
	if (name) {
		let splitName = name.split(" ").filter((item) => item !== "")
		let splitNameLength = splitName.length
		if (splitNameLength <= 1) {
			shortNameToReturn = name.substring(0, 2)
		} else {
			let firstName = splitName[0]
			let lastName = splitName[splitNameLength - 1]
			shortNameToReturn = firstName.substring(0, 1) + lastName.substring(0, 1)
		}
	}
	return shortNameToReturn.toUpperCase()
}

export const updateObject = (obj, newObject) => ({
	...obj,
	...newObject,
})

export const shortArray = (arr, type = "ASC", sortBy) => {
	if (arr.length === 0) return []

	const orderArrays = (curr, prev) => {
		let current = curr
		let pre = prev

		if (typeof curr === "string" && typeof prev === "string") {
			current = curr.toUpperCase()
			pre = prev.toUpperCase()
		}

		if (current > pre) {
			return 1
		} else if (current < pre) {
			return -1
		} else {
			return 0
		}
	}

	return arr.sort((a, b) => {
		let curr, prev
		if (typeof a === "object" && typeof b === "object" && sortBy) {
			if (type === "ASC") {
				curr = a[sortBy]
				prev = b[sortBy]
			} else {
				curr = b[sortBy]
				prev = a[sortBy]
			}
		} else if (
			(typeof a === "number" && typeof b === "number") ||
			(typeof a === "string" && typeof b === "string")
		) {
			if (type === "ASC") {
				curr = a
				prev = b
			} else {
				curr = b
				prev = a
			}
		}

		if (typeof a === "object" && typeof b === "object" && !sortBy) {
			return 0
		}
		return orderArrays(curr, prev)
	})
}

export const checkIfRefundable = (date, validTill = 5) => {
	const movementDateTZ = DateFNSTZ.utcToZonedTime(date, TIMEZONE_PT)
	let currentDateTZ = DateFNSTZ.utcToZonedTime(new Date(), TIMEZONE_PT)
	currentDateTZ = DateFNS.format(currentDateTZ, DATE_TIME_FORMAT)
	let refundDate = DateFNS.add(movementDateTZ, {
		days: validTill,
	})
	refundDate = DateFNS.format(refundDate, "yyyy-MM-dd 23:59:59")
	return currentDateTZ <= refundDate
}

export const dateFormatter = (date, type = "date-time") => {
	let dateTZ = DateFNSTZ.utcToZonedTime(date, TIMEZONE_PT)
	switch (type) {
		case "date":
			return DateFNS.format(dateTZ, DATE_FORMAT)
		case "time":
			return DateFNS.format(dateTZ, TIME_FORMAT)
		default:
			return DateFNS.format(dateTZ, DATE_TIME_FORMAT)
	}
}

export const GetFirstDayOfMonth = (date) => {
	if (date.getDate() < 15) date.setMonth(date.getMonth() - 1)
	return DateFNS.format(date, "yyyy-MM-01 00:00:00")
}
export const GetLastDayOfMonth = (date) => {
	let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
	return DateFNS.format(lastDay, "yyyy-MM-dd 23:59:59")
}

/* Date format change for datepicker */
export const dateFormateFromToTime = (date, type) => {
	let format = "yyyy-MM-dd 00:00:00"
	if (type === "to") format = "yyyy-MM-dd 23:59:59"
	return DateFNS.format(date, format)
}

export const Log = (message, error) => {
	if (process.env.REACT_APP_ENV === "development") {
		if (error) {
			console.error(message + " " + error)
		} else {
			console.log(message)
		}
	}
}

export const getMonthName = (list) => {
	let months = [
		"Jan",
		"Fev",
		"Março",
		"Abril",
		"Maio",
		"Junho",
		"Julho",
		"Agosto",
		"Set",
		"Out",
		"Nov",
		"Dez",
	]
	for (let data of list) {
		data.xAxis = months[data.xAxis]
	}
	return list
}

const getNTH = (day) => {
	let toReturn = "th"
	if (day === 1 || day === 21 || day === 31) {
		toReturn = "st"
	} else if (day === 2 || day === 22) {
		toReturn = "nd"
	} else if (day === 3 || day === 23) {
		toReturn = "rd"
	}
	return toReturn
}

export const getDays = (list) => {
	for (let data of list) {
		data.xAxis = `${data.xAxis}${getNTH(data.xAxis)}`
	}
	return list
}

export const getHours = (list) => {
	for (let data of list) {
		data.xAxis = `${data.xAxis}h`
	}
	return list
}

export const getError = (status) => {
	switch (status) {
		case 304:
			return "Os dados fornecidos são iguas aos anteriores."
		case 400:
			return "Os dados que fornece já existem."
		case 401:
			return "Não tem autorização para aceder."
		case 404:
			return "Não encontrado."
		case 405:
			return "Erro ao efetuar reembolso, o pagamento já foi processado ou reembolsado."
		case 408:
			return "Levou muito tempo para responder do servidor. Por favor, tente de novo em alguns momentos."
		case 409:
			return "Os dados que fornece já existem."
		case 422:
			return "Os dados que você fornece não são válidos."
		case 429:
			return "Demasiados pedidos. Por favor, aguarde 15 min e actualize a página."
		case 500:
			return "Alguma coisa correu mal! Por favor, tente novamente mais tarde..."
		case 503:
			return "O serviço não está disponível! Por favor, tente novamente mais tarde."
		case "errorLogin":
			return "Email ou palavra-passe inválidos!"
		default:
			return "Alguma coisa correu mal! Por favor, recarregue a página."
	}
}

export const selectNextOnEnter = (event, next) => {
	if (event.keyCode === 13 || event.keyCode === 9) {
		event.preventDefault()
		next.current.focus()
	}
}

export const UpdateInputChange = (data, value, key) => {
	let updatedObjects = updateObject(data, {
		[key]: updateObject(data[key], {
			value: value,
			valid: checkValidity(value, data, key),
			touched: true,
		}),
	})

	let isValidForm = true
	for (let inputIdentifier in updatedObjects) {
		if (inputIdentifier !== "submit") {
			isValidForm = updatedObjects[inputIdentifier].valid && isValidForm
		}
		if (updatedObjects[inputIdentifier].touched) {
			updatedObjects[inputIdentifier].valid = checkValidity(
				updatedObjects[inputIdentifier].value,
				updatedObjects,
				inputIdentifier
			)
		}
	}

	updatedObjects = updateObject(updatedObjects, {
		submit: updateObject(data.submit, {
			valid: isValidForm,
			element: updateObject(data.submit.element, {
				skin: isValidForm ? "primary" : "disable",
			}),
		}),
	})

	return updatedObjects
}

export const checkForFormValidity = (data) => {
	let isValidForm = true
	for (let inputIdentifier in data) {
		if (inputIdentifier !== "submit") {
			isValidForm = data[inputIdentifier].valid && isValidForm
		}
	}
	return isValidForm
}

export const updateInputChangeSingle = (data, value, key) => {
	let updatedObjects = updateObject(data, {
		[key]: updateObject(data[key], {
			value: value,
			valid: checkValidity(value, data, key),
			touched: true,
		}),
	})
	updatedObjects = updateObject(updatedObjects, {
		submit: checkForFormValidity(updatedObjects),
	})

	return updatedObjects
}

export const formatNumber = (number) => number.replaceAll(",", ".")

export const checkValidity = (value, element, key) => {
	if (!element) {
		return true
	}
	element[key].value = value
	element[key].touched = true

	let currentElement = element[key]
	let isValid = true

	if (currentElement.validation.required) {
		isValid = value !== "" && isValid
		if (currentElement.element.type === "checkbox") {
			isValid = value && isValid
		} else if (currentElement.element.type !== "number") {
			if (currentElement.validation.type === "obj") {
				isValid = true && isValid
			} else {
				isValid = value.trim() !== "" && isValid
			}
		}
	}

	if (currentElement.validation.min) {
		isValid = value.length >= currentElement.validation.min && isValid
	}

	if (currentElement.validation.max) {
		isValid = value.length <= currentElement.validation.max && isValid
	}

	if (currentElement.validation.minVal) {
		if (currentElement.element.type === "number") {
			value = formatNumber(value)
		}
		isValid = value >= currentElement.validation.minVal && isValid
	}

	if (currentElement.validation.maxVal) {
		if (currentElement.element.type === "number") {
			value = formatNumber(value)
		}
		isValid = value <= currentElement.validation.maxVal && isValid
	}

	if (currentElement.element.type === "email") {
		const pattern =
			/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
		isValid = pattern.test(value) && isValid
	}

	if (currentElement.validation.notEqual) {
		let toCompare = element[currentElement.validation.notEqual]
		if (toCompare.touched) {
			let otherVal = element[currentElement.validation.notEqual].value
			isValid = value !== otherVal && isValid
		}
	}

	if (currentElement.validation.equal) {
		let toCompare = element[currentElement.validation.equal]
		if (toCompare.touched) {
			let otherVal = element[currentElement.validation.equal].value
			isValid = value === otherVal && isValid
		}
	}

	return isValid
}
export const calculateColumn = (
	height,
	headerHeight = 42,
	rowHeight = 58.52,
	paginationHeight = 34
) => {
	let tHeight = 0
	let limit = 10

	if (height) {
		tHeight = height - paginationHeight
		let spaceForColumn = tHeight - headerHeight

		if (spaceForColumn > rowHeight)
			limit = Math.floor(spaceForColumn / rowHeight)
	}
	return { tHeight, limit }
}

export const valueFromNestedString = (obj, id) => {
	let arr = id.split(".")
	let arrLength = arr.length
	for (let i = 0; i < arrLength; ++i) {
		let key = arr[i]
		if (key in obj) {
			obj = obj[key]
		} else {
			return
		}
	}
	return obj
}

export const formatWithEuro = (amount) => {
	return new Intl.NumberFormat("pt-PT", {
		style: "currency",
		currency: "EUR",
	}).format(Number(amount).toFixed(2))
}

export const formatAmount = (amount) => {
	if (amount === 0) return "-"
	return formatWithEuro(Math.abs(amount) / 100)
}
