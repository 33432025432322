import React from "react";
import Tooltip from "@mui/material/Tooltip";
import ListIcon from "@mui/icons-material/List";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const UseSwitchView = ({ change, current, isSmallDevice }) => {
  let isChart = current === "chart";
  const switchClickHandler = () => change(isChart ? "list" : "chart");
  return (
    <span>
      <Tooltip
        title={isChart ? "Lista" : "Quadro"}
        arrow
        placement="left"
        disabled={!isSmallDevice}
      >
        {isChart ? (
          <ListIcon onClick={switchClickHandler} />
        ) : (
          <TrendingUpIcon onClick={switchClickHandler} />
        )}
      </Tooltip>
    </span>
  );
};

export default UseSwitchView;
