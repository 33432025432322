import React from "react";
import Styled from "styled-components";
import Branding from "../shared/branding";
import IOS from "../../download/IOS.png";
import IOS_Icon from "../../download/app-store.svg";
import Google from "../../download/Google_Play.png";
import Google_Icon from "../../download/google-play.png";
import Logo from "../../logo.png";

import { useSizeWithChild } from "../shared/useWindowSize";

const Download = () => {
	const downloadRef = React.useRef();
	let { height } = useSizeWithChild(downloadRef);
	return (
		<DownloadStyled ref={downloadRef}>
			<div style={{ height: `${height / 3}px` }}>
				<img src={Logo} className="logo" />
				<Branding branding="name" />
			</div>
			<a
				style={{ height: `${height / 3}px` }}
				href="https://apps.apple.com/pt/app/qrkoin/id1547791345"
				target="_blank"
			>
				<img src={IOS} className="store" />
				<span>
					<img src={IOS_Icon} style={{ height: "80%" }} />
				</span>
			</a>
			<a
				style={{ height: `${height / 3}px` }}
				href="https://play.google.com/store/apps/details?id=pt.qrkoin.android"
				target="_blank"
			>
				<img src={Google} className="store" />
				<span>
					<img src={Google_Icon} />
				</span>
			</a>
		</DownloadStyled>
	);
};

export default Download;

const DownloadStyled = Styled.div`
    ${(props) => {
			return `
            padding:20px 0;
            width:100%;
            height:calc(100% - 40px);
            display:flex;
            flex-direction: column;

            justify-content: space-between;
            align-items: stretch;
            overflow:auto;

            
            >div, a{
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;

                display:flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;


                >img{
                    height:45%;
                }

                .logo{
                    height:40%;
                }
                
                >span{
                    height:30%;
                    >img{
                        height:100%;
                    }
                    margin-top:20px;
                }
            }
        `;
		}}
`;
