import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import RoomIcon from "@mui/icons-material/Room";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import EuroIcon from "@mui/icons-material/Euro";

import layout from "../../layout/layout";
import {
  CardWrapperStyled,
  CardStyled,
  BoxStyled,
  CardHeaderStyled,
  CardBodyStyled,
  CardFooterStyled,
} from "../../theme";
import {
  formatAmount,
  getError,
  shortArray,
  updateObject,
} from "../shared/Utility";
import { Get } from "../../server-requests/serverRequest";
import { AuthProvider } from "../../server-requests/authContextProvider";
import { LoadingComponent } from "../shared/Loading";
import EditPrice from "./EditPrice";
import UseTable from "../shared/useTable";
import useWindowSize, { useSizeWithChild } from "../shared/useWindowSize";

const Dashboard = () => {
  const pricePopupRef = React.useRef();
  const wrapDivRef = React.useRef();

  const { width: windowsWidth } = useWindowSize();
  let isSmallDevice = windowsWidth < 991;
  const wrapDiv = useSizeWithChild(wrapDivRef);
  let tableHeight;
  if (wrapDiv.height && wrapDiv.width) {
    tableHeight = wrapDiv.height;
  }
  if (isSmallDevice) {
    tableHeight = 280;
  }

  const { snackbar } = React.useContext(AuthProvider);

  const [data, setData] = React.useState({
    location: {
      isLoading: true,
      list: [],
      fullList: [],
      selected: null,
      reload: true,
      isFilter: false,
      filterBy: {
        name: "",
      },
    },
    device: {
      isLoading: true,
      list: [],
      fullList: [],
      selected: null,
      reload: false,
      isFilter: false,
      filterBy: {
        name: "",
      },
    },
    price: {
      isLoading: true,
      list: [],
      fullList: [],
      reload: false,
      isFilter: false,
      filterBy: {
        name: "",
      },
    },
    product: {
      isLoading: true,
      reload: true,
      list: [],
    },
  });

  // get price list from server
  React.useEffect(() => {
    let isMounted = true;
    if (data.price.reload && data.device.selected) {
      Get("/prices/list", { deviceId: data.device.selected.id })
        .then((items) => {
          if (isMounted) {
            let shortedList = shortArray(items, "amount", "ASC");
            setData((prev) =>
              updateObject(prev, {
                price: updateObject(prev.price, {
                  list: shortedList,
                  fullList: shortedList,
                  isLoading: false,
                  reload: false,
                }),
              })
            );
          }
        })
        .catch((status) => {
          setData((prev) =>
            updateObject(prev, {
              price: updateObject(prev.price, {
                isLoading: false,
                reload: false,
              }),
            })
          );
          snackbar(getError(status));
        });
    }
    return () => (isMounted = false);
  }, [data.price.reload, data.device.selected, snackbar]);

  const updatePriceSuccessHandler = () => {
    snackbar("Preço actualizado com sucesso.", "success");
    setData((prev) =>
      updateObject(prev, {
        price: updateObject(prev.price, {
          isLoading: true,
          reload: true,
        }),
      })
    );
  };

  let pricesElement = null;
  let priceEmptyText = `Por favor, seleccione o dispositivo`;
  if (data.device.selected && data.device.selected !== "")
    priceEmptyText = `Não tem nenhum produtos nesta ${data.device.selected.name}!`;
  if (!data.price.isLoading) {
    if (data.price.list.length > 0 && tableHeight) {
      pricesElement = (
        <UseTable
        defaultOrderBy='product.name'
          tableHeight={tableHeight}
          data={data.price.list}
          columns={[
            {
              id: "product.name",
              label: "Produtos",
              searchBy: false,
              orderBy: true,
              align: "left",
            },
            {
              id: "amount",
              label: "Preço",
              searchBy: false,
              orderBy: false,
              align: "left",
              format: (value) => formatAmount(value),
            },
            {
              id: "pulses",
              label: "Impulsos",
              searchBy: false,
              orderBy: false,
              align: "left",
            },
            {
              id: "action",
              label: "",
              align: "center",
              width: 40,
              actions: [
                {
                  id: "edit",
                  display: "icon", //icon, text, both
                  text: "Editar",
                  className: "edit",
                  icon: <EditIcon />,
                  click: (item) =>
                    pricePopupRef.current.Open(item, data.device.selected.id),
                },
              ],
            },
          ]}
          headerHeight={54.77}
          rowHeight={50.52}
          paginationHeight={48}
        />
      );
    } else {
      pricesElement = priceEmptyText;
    }
  } else {
    pricesElement = <LoadingComponent height={tableHeight} />;
  }
  
  if(data.device.list.length === 0 && data.location.selected && !data.device.isLoading) {
    pricesElement = `Não existe nenhum dispositivo em ${data.location.selected.name}`;
  }

  let footerPriceElement = null;
  if (!data.price.isLoading && data.device.selected) {
    footerPriceElement = (
      <>
        Tem <b>{data.price.list.length}</b> produtos em
        <b>{data.device.selected.name}.</b>
      </>
    );
  }

  // get devices list from server
  React.useEffect(() => {
    let isMounted = true;
    if (data.device.reload && data.location.selected) {
      Get("/devices/list", { locationId: data.location.selected.id })
        .then((items) => {
          if (isMounted) {
            setData((prev) => {
              let updatedDevice = {
                list: items,
                fullList: items,
                isLoading: false,
                reload: false,
              };
              if (items.length > 0) {
                updatedDevice = {
                  list: items,
                  fullList: items,
                  isLoading: false,
                  reload: false,
                  selected: items[0],
                };
              }

              let updatedObject = updateObject(prev, {
                device: updateObject(prev.device, updatedDevice),
              });
              if (items.length > 0) {
                updatedObject = updateObject(prev, {
                  device: updateObject(prev.device, updatedDevice),
                  price: updateObject(prev.price, { reload: true }),
                });
              }
              return updatedObject;
            });
          }
        })
        .catch((status) => {
          setData((prev) =>
            updateObject(prev, {
              device: updateObject(prev.device, {
                isLoading: false,
                reload: false,
              }),
            })
          );
          snackbar(getError(status));
        });
    }
    return () => (isMounted = false);
  }, [data.device.reload, data.location.selected, snackbar]);

  let devicesElement = null;
  let deviceEmptyText = `Por favor, seleccione o localização`;
  if (data.location.selected && data.location.selected.name)
    deviceEmptyText = `Não tem nenhuma dispositivos nesta ${data.location.selected.name}!`;
  if (!data.device.isLoading) {
    if (data.device.list.length > 0 && tableHeight) {
      devicesElement = (
        <UseTable
          defaultOrderBy='name'
          tableHeight={tableHeight}
          data={data.device.list}
          columns={[
            {
              id: "name",
              label: "Dispositivos",
              searchBy: false,
              orderBy: true,
              align: "left",
            },
            {
              id: "online",
              label: "Online",
              searchBy: false,
              orderBy: true,
              align: "left",
              format: (value) => (
                <span className={value ? "" : "alert"}>
                  {value ? "Ligado" : "Desligado"}
                </span>
              ),
            },
            {
              id: "outOfService",
              label: "Serviço",
              searchBy: false,
              orderBy: true,
              align: "left",
              format: (value) => (
                <span className={value ? "alert" : ""}>
                  {value ? "Fora de serviço" : "Em serviço"}
                </span>
              ),
            },
          ]}
          rowClick={(row) => {
            footerPriceElement = null;
            setData((prev) =>
              updateObject(prev, {
                device: updateObject(prev.device, {
                  selected: row,
                }),
                price: updateObject(prev.price, {
                  reload: true,
                  isLoading: true,
                }),
              })
            );
          }}
          headerHeight={54.77}
          rowHeight={50.52}
          paginationHeight={48}
        />
      );
    } else {
      devicesElement = deviceEmptyText;
    }
  } else {
    devicesElement = <LoadingComponent height={tableHeight} />;
  }
  let footerTextDevices = null;
  if (!data.device.isLoading && data.location.selected) {
    footerTextDevices = (
      <>
        Tem <b>{data.device.list.length}</b> dispositivos em{" "}
        <b>{data.location.selected.name}.</b>
      </>
    );
  }

  // get locations list from server
  React.useEffect(() => {
    let isMounted = true;
    if (data.location.reload) {
      Get("/locations/list")
        .then((items) => {
          if (isMounted) {
            setData((prev) => {
              let updateLocation = {
                list: items,
                fullList: items,
                reload: false,
                isLoading: false,
              };
              if (items.length > 0) updateLocation.selected = items[0];

              let updatedObject = updateObject(prev, {
                location: updateObject(prev.location, updateLocation),
              });
              if (items.length > 0)
                updatedObject.device = updateObject(prev.device, {
                  reload: true,
                });
              return updatedObject;
            });
          }
        })
        .catch((status) => {
          setData((prev) =>
            updateObject(prev, {
              location: updateObject(prev.location, {
                reload: false,
                isLoading: false,
              }),
              device: updateObject(prev.device, {
                reload: false,
                isLoading: false,
              }),
              price: updateObject(prev.price, {
                reload: false,
                isLoading: false,
              }),
            })
          );
          snackbar(getError(status));
        });
    }
    return () => (isMounted = false);
  }, [data.location.reload, snackbar]);

  let locationsElement = null;
  if (!data.location.isLoading) {
    if (data.location.list.length > 0 && tableHeight) {
      locationsElement = (
        <UseTable
          defaultOrderBy='name'
          tableHeight={tableHeight}
          data={data.location.list}
          columns={[
            {
              id: "name",
              label: "Localizações",
              searchBy: false,
              orderBy: true,
              align: "left",
            },
          ]}
          rowClick={(row) => {
            footerTextDevices = null;
            footerPriceElement = null;
            setData((prev) =>
              updateObject(prev, {
                location: updateObject(prev.location, {
                  selected: row,
                }),
                device: updateObject(prev.device, {
                  selected: null,
                  list:[],
                  reload: true,
                  isLoading: true,
                }),
                price: updateObject(prev.price, {
                  selected: null,
                  isLoading: true,
                }),
              })
            );
          }}
          headerHeight={54.77}
          rowHeight={58.52}
          paginationHeight={48}
        />
      );
    } else {
      locationsElement = "Não existem dados para localização.";
    }
  } else {
    locationsElement = <LoadingComponent height={tableHeight} />;
  }
  let footerTextLocations = null;
  if (!data.location.isLoading)
    footerTextLocations = (
      <>
        Tem <b>{data.location.list.length}</b> localizações.
      </>
    );

  return (
    <CardWrapperStyled ref={wrapDivRef}>
      <CardStyled width="33" col="3">
        <BoxStyled>
          <CardHeaderStyled>
            <h2>
              <RoomIcon />
              Localizações
            </h2>
          </CardHeaderStyled>
          <CardBodyStyled
            isEmpty={data.location.list.length === 0}
            height={tableHeight}
          >
            {locationsElement}
          </CardBodyStyled>
          <CardFooterStyled>{footerTextLocations}</CardFooterStyled>
        </BoxStyled>
      </CardStyled>
      <CardStyled width="33" col="3">
        <BoxStyled>
          <CardHeaderStyled>
            <h2>
              <DevicesOtherIcon />
              Dispositivos - {data.location.selected ? data.location.selected.name :''}
            </h2>
          </CardHeaderStyled>
          <CardBodyStyled
            isEmpty={data.device.list.length === 0}
            height={tableHeight}
          >
            {devicesElement}
          </CardBodyStyled>
          <CardFooterStyled>{footerTextDevices}</CardFooterStyled>
        </BoxStyled>
      </CardStyled>
      <CardStyled width="33" col="3">
        <BoxStyled>
          <CardHeaderStyled>
            <h2>
              <EuroIcon />
              Lista de preços - {data.device.selected? data.device.selected.name:''}
            </h2>
          </CardHeaderStyled>
          <CardBodyStyled
            isEmpty={data.price.list.length === 0 || (data.device.list.length === 0 && data.location.selected && !data.device.isLoading)}
            height={tableHeight}
          >
            {pricesElement}
          </CardBodyStyled>
          <CardFooterStyled>{footerPriceElement}</CardFooterStyled>
        </BoxStyled>
      </CardStyled>

      <EditPrice ref={pricePopupRef} success={updatePriceSuccessHandler} />
    </CardWrapperStyled>
  );
};

export default layout(Dashboard);
