import React, {useRef} from "react";
import Tooltip from "@mui/material/Tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { dateFormatter, valueFromNestedString } from "./Utility";
import Conformation from "../reports/Conformation";

const formattedData = (rows, columns) => {
  let toReturn = [];

  for (let row of rows) {
    let objToPush = {};
    for (let column of columns) {
      let title = column.label;
      let unFormattedValue = valueFromNestedString(row, column.id);
      let value = column.format
        ? column.format(unFormattedValue, row, "report")
        : unFormattedValue;

      if (column.id === "amount")
        value = Number(Math.abs(unFormattedValue / 100).toFixed(2));
      if (column.id === "commission") {
        value = row.mbwayId
          ? "-"
          : Number(Math.abs(unFormattedValue / 100).toFixed(2));
      }
      if (column.id === "mbwayId") value = row[column.id] ? "MBWay" : "QRKoin";

      objToPush[title] = value;
    }
    toReturn.push(objToPush);
  }
  return toReturn;
};

const UseExportToExcel = ({ rows, columns, filter }) => {
  const conformationRef = useRef();

  const exportToXLSX = () => {
    let tableData = formattedData(rows, columns);
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = { Sheets: { Relatório: ws }, SheetNames: ["Relatório"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(
      data,
      `Relatório_de_venda_${dateFormatter(new Date())}.xlsx`
    );
  };

  const openConformation = ()=>{
    conformationRef.current.Open(filter)
  }
  return (
    <>
    <span onClick={openConformation}>
      <Tooltip title="Download" arrow placement="top">
        <FileDownloadIcon />
      </Tooltip>
      
    </span>
    <Conformation ref={conformationRef} download={exportToXLSX} />
    </>
  );
};

export default UseExportToExcel;
