import React from "react";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../firebase";
import { Get } from "./serverRequest";
import { getError, Log } from "../components/shared/Utility";
import Loading from "../components/shared/Loading";
import UseSnackbar from "../components/shared/useSnackbar";

export const AuthProvider = React.createContext();

const checkForByPassEmail = (email) => {
  let emailList = process.env.REACT_APP_BYPASS_EMAIL;
  emailList.replace(" ", "");
  emailList.split(",");
  return emailList.includes(email);
};

const AuthContext = ({ children }) => {
  const snackbarRef = React.useRef();

  const [user, setUser] = React.useState(null);
  const [firebaseUser, setFirebaseUser] = React.useState(null);
  const [authLoading, setAuthLoading] = React.useState(true);
  const [isVerified, setIsVerified] = React.useState(true);

  // type = 'errors', 'success', 'warning', 'info' and action = 'normal', 'notification', 'logout', 'push'
  const snackbar = React.useCallback(
    (message, types = "errors", action = "normal") =>
      snackbarRef.current.Open(message, types, action),
    []
  );

  React.useEffect(() => {
    if (authLoading) {
      onAuthStateChanged(auth, (firebaseUser) => {
        Log("User is " + (firebaseUser ? "logged." : "not logged."));
        if (firebaseUser) {
          Get("users")
            .then((user) => {
              if (user) {
                setUser(user);
                setAuthLoading(false);
              }
            })
            .catch((status) => {
              Log("Error: get-users--authContextProvider.js", status);
              setAuthLoading(false);
              snackbar(getError("errorLogin"));
            });
          setFirebaseUser(firebaseUser);
          let isVerified = firebaseUser.emailVerified;
          if (checkForByPassEmail(firebaseUser.email)) isVerified = true;
          setIsVerified(isVerified);
        } else {
          setUser(null);
          setFirebaseUser(null);
          setAuthLoading(false);
        }
      });
    }
  }, [authLoading, snackbar]);

  return (
    <AuthProvider.Provider
      value={{
        authLoading,
        setAuthLoading,
        user,
        setUser,
        firebaseUser,
        setFirebaseUser,
        isVerified,
        setIsVerified,
        snackbar,
      }}
    >
      {authLoading ? <Loading height={"100vh"} /> : children}
      <UseSnackbar ref={snackbarRef} />
    </AuthProvider.Provider>
  );
};
export default AuthContext;
