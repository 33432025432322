import React from "react";
import PropTypes from "prop-types";

import { FilterMovementStyled } from "../../theme";
import { dateFormateFromToTime, updateObject } from "../shared/Utility";
import UseSelect from "../shared/useSelect";
import UseDatePicker from "../shared/useDatePicker";

const Filter = (props) => {
	const [filterBy, setFilterBy] = React.useState({ ...props.filterBy });

	const allFilterChangeHandler = (value, key) => {
		if (key === "to" || key === "from")
			value = dateFormateFromToTime(value, key);
		let updatedObj = updateObject(filterBy, { [key]: value });
		setFilterBy(updatedObj);
		props.filter(updatedObj, key);
	};

	return (
		<FilterMovementStyled>
			<UseDatePicker
				name="from"
				label="Desde"
				value={filterBy.from}
				className="marginBottom"
				onChange={(e) => allFilterChangeHandler(e, "from")}
			/>
			<UseDatePicker
				name="to"
				label="Até"
				value={filterBy.to}
				className="marginBottom"
				onChange={(e) => allFilterChangeHandler(e, "to")}
			/>

			<div className="outline md">
				{props.view === "list" && (
					<>
						<UseSelect
							config={{
								className: "marginBottom",
								label: "Localização",
								value: filterBy.location,
								element: {
									name: "location",
									onChange: (e) =>
										allFilterChangeHandler(e.target.value, "location"),
									options: props.locations,
								},
							}}
						/>

						<UseSelect
							config={{
								className: "marginBottom",
								label: "Dispositivo",
								value: filterBy.device,
								element: {
									name: "device",
									onChange: (e) =>
										allFilterChangeHandler(e.target.value, "device"),
									options: props.devices,
								},
							}}
						/>

						<UseSelect
							config={{
								className: "marginBottom",
								label: "Métodos de Pagamento",
								value: filterBy.device,
								element: {
									name: "payment",
									onChange: (e) =>
										allFilterChangeHandler(e.target.value, "payment"),
									options: [
										{ value: "all", label: "Todos" },
										{ value: "qrkoin", label: "QRKoin" },
										{ value: "mbway", label: "MB Way" },
									],
								},
							}}
						/>
						<UseSelect
							config={{
								className: "marginBottom",
								label: "Processado",
								value: filterBy.process,
								element: {
									name: "process",
									onChange: (e) =>
										allFilterChangeHandler(e.target.value, "process"),
									options: [
										{ value: "all", label: "Todos" },
										{ value: "processed", label: "Processado" },
										{ value: "not-processed", label: "Não processado" },
									],
								},
							}}
						/>
						<UseSelect
							config={{
								label: "Reembolsado",
								value: filterBy.refund,
								element: {
									name: "refund",
									onChange: (e) =>
										allFilterChangeHandler(e.target.value, "refund"),
									options: [
										{ value: "all", label: "Todos" },
										{ value: "refunded", label: "Reembolsado" },
										{ value: "not-refunded", label: "Não reembolsado" },
									],
								},
							}}
						/>
					</>
				)}
			</div>
		</FilterMovementStyled>
	);
};

Filter.propTypes = {
	filter: PropTypes.func.isRequired,
	filterBy: PropTypes.object.isRequired,
};

export default Filter;
