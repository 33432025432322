import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthProvider } from "../../server-requests/authContextProvider";

const PrivateRoute = ({ children }) => {
  const { user } = React.useContext(AuthProvider);
  let location = useLocation();
  return user ? (
    children
  ) : (
    <Navigate
      to="/"
      state={{
        from: location.pathname,
      }}
    />
  );
};

export default PrivateRoute;
