import React from "react";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import { calculateColumn, valueFromNestedString } from "./Utility";

const UseTable = ({
  data,
  tableWidth = "100%",
  tableHeight,
  columns,
  className = "",
  addedColumn,
  rowClick,
  headerHeight = 54.74,
  rowHeight = 58.52,
  paginationHeight = 52,
	defaultOrderBy = '',
}) => {
  const { tHeight, limit } = calculateColumn(
    tableHeight,
    addedColumn ? 2 * headerHeight : headerHeight,
    rowHeight,
    paginationHeight
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(limit);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

  // needed if search function is open
  // const [searched, setSearched] = React.useState("");
  // let columnSearchBy = columns.filter((colum) => colum.searchBy);

  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const tableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    let first = valueFromNestedString(a, orderBy);
    let second = valueFromNestedString(b, orderBy);
  
    // If both values are strings, compare them using localeCompare
    if (typeof first === 'string' && typeof second === 'string') {
      return second.localeCompare(first, undefined, {numeric: true});
    }
  
    // Otherwise, convert both values to numbers and compare them
    first = parseFloat(first);
    second = parseFloat(second);
    if (isNaN(first)) first = 0;
    if (isNaN(second)) second = 0;
  
    if (second < first) return -1;
    if (second > first) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  React.useEffect(() => {
    setRowsPerPage(limit);
  }, [limit]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper
      sx={{
        width: tableWidth,
        overflow: "hidden",
      }}
      className={`customTable ${className}`}
    >
      <TableContainer sx={{ height: `${tHeight}px` }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {addedColumn && (
              <TableRow>
                {addedColumn.map((column, i) => (
                  <TableCell
                    key={i}
                    align={column.align}
                    colSpan={column.colSpan}
                    className={`noBorderBottom ${column.className}`}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            )}
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "bold",
                    width: column.width,
                  }}
                  className={column.className}
                  rowSpan={2}
                >
                  {column.orderBy ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableSort(data, getComparator(order, orderBy))
              // needed if search function is open
              // .filter((row) => {
              //   let toReturn = true;
              //   columnSearchBy.forEach((element) => {
              //     let searchedItem = valueFromNestedString(row, element.id);
              //     let inputItem = searched;

              //     if (typeof searchedItem === "string") {
              //       searchedItem = searchedItem.toLowerCase();
              //       inputItem = inputItem.toLowerCase();
              //     } else {
              //       searchedItem = searchedItem.toString();
              //       inputItem = inputItem.toString();
              //     }

              //     let item = searchedItem.includes(inputItem);
              //     toReturn = toReturn && item;
              //     if (item) return;
              //   });
              //   return toReturn;
              // })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                let rowClass = rowClick ? "selectRow" : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={
                      index % 2 === 0 ? `even ${rowClass}` : `odd ${rowClass}`
                    }
                    onClick={() => rowClick && rowClick(row)}
                  >
                    {columns.map((column, index) => {
                      const value = valueFromNestedString(row, column.id);
                      return (
                        <TableCell key={index} align={column.align}>
                          <>
                            {column.id !== "action" && column.format
                              ? column.format(value, row)
                              : value}
                            {column.id === "action" &&
                              column.actions.length > 0 &&
                              column.actions.map((item, index) => {
                                let displayIcon = null;
                                if (
                                  item.display === "icon" ||
                                  item.display === "both"
                                )
                                  displayIcon = item.icon;

                                let displayText = null;
                                if (
                                  item.display === "text" ||
                                  item.display === "both"
                                )
                                  displayText = item.text;

                                let classElement = "action text";
                                if (item.display === "icon")
                                  classElement = "action icon";
                                if (item.display === "both")
                                  classElement = "action text icon";
                                return (
                                  <Tooltip
                                    title={item.text}
                                    placement="top"
                                    arrow
                                    key={index}
                                  >
                                    <span
                                      onClick={() => item.click(row)}
                                      className={`${item.className} ${classElement}`}
                                    >
                                      {displayIcon}
                                      {displayText}
                                    </span>
                                  </Tooltip>
                                );
                              })}
                          </>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default UseTable;

UseTable.propTypes = {
  data: PropTypes.array.isRequired,
  tableWidth: PropTypes.any,
  tableHeight: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  addedColumn: PropTypes.array,
};
