import React from "react";
import Styled from "styled-components";
import RefreshIcon from "@mui/icons-material/Refresh";
import LockIcon from "@mui/icons-material/Lock";

import {
  getError,
  selectNextOnEnter,
  UpdateInputChange,
} from "../shared/Utility";
import { FirebaseChangePassword } from "../../firebase";
import { AuthProvider } from "../../server-requests/authContextProvider";
import FormHooks from "../shared/formHooks";

const ChangePassword = () => {
  const submitRef = React.useRef();
  const newPasswordRef = React.useRef();
  const repeatPasswordRef = React.useRef();
  const { firebaseUser, snackbar } = React.useContext(AuthProvider);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formInputs, setFormInputs] = React.useState({
    password: {
      label: "Palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
        notEqual: "newPassword",
      },
      inputRef: null,
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Palavra-passe",
        autoComplete: "old_password",
        onKeyDown: (e) => selectNextOnEnter(e, newPasswordRef),
      },
    },
    newPassword: {
      label: "Nova palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
        notEqual: "password",
        equal: "newPassword",
      },
      inputRef: newPasswordRef,
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Nova palavra-passe",
        autoComplete: "new_password",
        onKeyDown: (e) => selectNextOnEnter(e, repeatPasswordRef),
      },
    },
    confirmPassword: {
      label: "Repita a palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
        equal: "newPassword",
      },
      inputRef: repeatPasswordRef,
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Nova palavra-passe",
        autoComplete: "repita_password",
        onKeyDown: (e) => selectNextOnEnter(e, submitRef),
      },
    },
    submit: {
      label: "Alterar",
      icons: {
        loading: true,
        icon: <RefreshIcon />,
      },
      validation: {
        required: true,
      },
      inputRef: submitRef,
      valid: false,
      element: {
        type: "button",
        display: "full",
      },
    },
  });

  const inputChangeHandler = (value, key) =>
    setFormInputs(UpdateInputChange(formInputs, value, key));

  const changePasswordHandler = (e) => {
    if (formInputs.submit.valid) {
      e.preventDefault();
      setIsLoading(true);

      FirebaseChangePassword(
        firebaseUser.email,
        formInputs.password.value,
        formInputs.newPassword.value
      )
        .then(() => {
          snackbar(
            "Palavra-passe alterada com sucesso. Por favor, faça sair e volte a entrar com nova palavra-passe?",
            "success",
            "logout"
          );
          setIsLoading(false);
        })
        .catch((status) => {
          setIsLoading(false);
          snackbar(getError(status));
        });
    }
  };

  return (
    <ChangePasswordStyled>
      <FormHooks
        formInputs={formInputs}
        inputChange={inputChangeHandler}
        onSubmit={changePasswordHandler}
        loading={isLoading}
      />
    </ChangePasswordStyled>
  );
};

export default ChangePassword;

const ChangePasswordStyled = Styled.div`
    ${(props) => {
      let theme = props.theme;
      return `
        padding:${theme.gLG};
        width:auto;
        `;
    }}
`;
