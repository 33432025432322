import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import UseDialog from "./useDialog";
import { IframeStyled } from "../../theme";

const Footer = ({ login, isLanding }) => {
  let dialogRef = React.useRef();
  let currentYear = new Date().getFullYear();
  return (
    <FooterStyled isLogin={login} isLanding={isLanding}>
      <div className="wrapper">
        <b>
          © {currentYear} {process.env.REACT_APP_PROJECT_NAME}.
        </b>
        <PolicyLink
          text="Política de Privacidade e Cookies"
          onClick={() => dialogRef.current.Open()}
          name="privacy"
        />
      </div>
      <UseDialog ref={dialogRef} title="Política de Privacidade e Cookies">
        <div className="body">
          <IframeStyled src="https://storage.googleapis.com/qrkoin_privacy_files/policy.html" />
        </div>
      </UseDialog>
    </FooterStyled>
  );
};

Footer.propTypes = {
  login: PropTypes.bool,
  isLanding: PropTypes.bool,
};

export default Footer;

const FooterStyled = styled.div`
  ${(props) => {
    let theme = props.theme;
    return `
        display:flex;
        box-sizing: border-box;
        align-items:center;
        color: rgba(${theme.black},0.8);
        ${
          !props.isLogin &&
          `
          border-top:1px solid rgba(${theme.black}, 0.0625);
          ${theme.headerFooterHeight};
          padding-right:${theme.gLG};
          padding-left:${theme.gLG};

          `
        };
        ${
          props.isLogin &&
          `
          justify-content:center;
          margin-top:${theme.gLG};
          `
        };
        .wrapper{
          display:flex;
          box-sizing: border-box;
          align-items:center;
        }
        ${
          props.isLanding &&
          `
            .wrapper{
              width:1050px;
              margin:0 auto;
            }

            ${theme.mMX_LG} {
              .wrapper{
                width:90%;
              }
            }
          `
        }
        a, span{
          cursor: pointer;
          color: rgba(${theme.black},0.4);
          margin-left:${theme.gSM};
          padding-left:${theme.gSM};
          border-left:1px solid rgba(${theme.black}, 0.2);
          :hover{
            color: rgb(${theme.primary});
          }
        }
    `;
  }}
`;
const PolicyLink = ({ text, onClick, name, translate }) => {
  return (
    <span onClick={onClick} name={name}>
      {text}
    </span>
  );
};
