import Styled, { createGlobalStyle } from "styled-components"
export const tableStriped = {
	cOne: "#FFF",
	cTwo: "#F7F7F7",
	cThree: "#E6E6E6",
}

export const allHeight = 70
export const currentTheme = {
	primary: "255, 193, 7",
	secondary: "55, 71, 79",
	primaryHASH: "#fec109",
	secondaryHASH: "#37474f",

	white: "255, 255, 255",
	black: "0,0,0",
	orange: "255, 87, 34",
	red: "245, 0, 87",
	green: "81, 187, 131",
	blue: "0,146,181",

	fSM: `font-size: 12px`,
	fDF: `font-size: 14px`,
	fMD: `font-size: 16px`,
	fLG: `font-size: 18px`,
	fXL: `font-size: 22px`,
	fXXL: `font-size: 32px`,
	fXXXL: `font-size:36px`,

	gSM: "5px",
	gMD: "10px",
	gLG: "15px",
	gXL: "20px",

	fontPrimary: `font-family: 'Roboto', sans-serif`,
	fontSecondary: `font-family:  Arial`,
	fontMono: `font-family:  'Roboto Mono', monospace`,

	mMX_XXS: "@media (max-width: 375px)",
	mMX_XS: "@media (max-width: 500px)",
	mMX_DF: "@media (max-width: 620px)",
	mMX_SM: "@media (max-width: 768px)",
	mMX_MD: "@media (max-width: 991px)",
	mMX_LG: "@media (max-width: 1100px)",
	mMX_XL: "@media (max-width: 1200px)",

	headerFooterHeight: `
    height:${allHeight}px;
    min-height:${allHeight}px;
    max:height:${allHeight}px;
  `,
}

export const GlobalStyle = createGlobalStyle`
    ${(props) => {
			let theme = props.theme
			return `
        body, html
        {
            background:rgb(${theme.white})!important;
            height: 100%!important;
            overflow: hidden!important;
            -webkit-overflow-scrolling: touch;
        
            margin: 0!important;
            padding: 0!important;
            min-width: unset!important;
            ${theme.fontPrimary}!important;
            ${theme.fDF}!important;
            line-height: 1.4285em!important;
            color: rgba(${theme.black}, 0.87)!important;
          }

        .MuiTable-root .MuiSvgIcon-root
        {
          cursor:pointer;
        }

        * 
        {
        margin: 0;
        padding: 0;
        }

        a
        {
            text-decoration: none;
        }

        h1,h2,h3,h4,h5,h6
        {
        ${theme.fontPrimary}!important;
        margin: 0;
        padding: 0;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  
        {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        #root
        {
            height:100%;
            overflow: hidden!important;
        }

        .MuiInputLabel-shrink
        {
          background:rgba(${theme.white})!important;
          padding:0 ${theme.gSM}!important;
          ${theme.fLG}!important;
        }
        .Mui-focused
        {
          color:rgba(${theme.black},0.5)!important;
        }
        .Mui-error
        {
          color:rgba(${theme.red})!important;
        }
        .Mui-error .MuiOutlinedInput-notchedOutline
        {
          border-width:1px!important;
          border-color:rgba(${theme.red})!important;
        }
        .MuiAutocomplete-option[aria-selected="true"]
        {
          background:rgba(${theme.primary},0.1)!important;
        }

        .marginBottom
        {
          margin-bottom:15px!important;
        }
        .marginTop
        {
          margin-top:15px!important;
        }

        .MuiToolbar-regular
        {
          min-height:48px!important;
        }

        .MuiInputBase-fullWidth 
        {
          height: 50px;
        }

        .gm-ui-hover-effect
        {
          display: none!important;
        }

        .noPadding{
          padding:0!important;
        }


        // Input 

          .input{
            margin-bottom:${theme.gXL}!important;
          }

          .error>label{
            color:rgb(${theme.red})!important;
          }
          .error>input{
            border:1px solid rgb(${theme.red})!important;
          }

          // icon left
          .input.icon.left > .placeholder,
          .input.icon.password.left> .placeholder{
            left: 40px!important;
          }
          .input.icon.left >span,
          .input.icon.password.left >span{
              top:1px;
              left:1px;
          }
          .input.icon.left >input{
            width:calc(100% - 52px);
            padding: 0 10px 0 40px;
          }

          // icon right
          .input.icon.right >span,
          .input.password.icon.right >span{
              top:1px;
              right:1px;
          }
          .input.icon.right >input{
            width:calc(100% - 52px);
            padding: 0 40px 0 10px;
          }
          .input.password.icon >input{
            padding: 0 40px 0 40px;
            width:calc(100% - 82px);
          }

          .input.password.icon.right>.showHide{
              left: 1px;
              right:unset!important;
          }


          // custom Table MUI
          .customTable th{
            background:rgb(${theme.primary})!important;
          }
          .odd>td
          {
            background:rgb(${theme.white})!important;
          }
          .even>td
          {
            background:#f2f2f5!important;
          }

          .alert{
            color:rgba(${theme.red},1);
            font-weight:bold;
          }

          .customTable .delete{
            cursor:pointer;
            :hover{
              color:rgba(${theme.red},1);
            }
          }
          .customTable .edit{
            cursor:pointer;
            margin-right:5px;
            :hover{
              color:rgba(${theme.orange},1);
            }
          }
          
          .customTable tr{
            cursor:pointer;
            :hover{
              >td{
                background:#dedede!important;
              }
            }
          }
        `
		}}
`
export const OutWrapper = Styled.div`
    ${() => {
			return `
        display: flex;
        height: 100%;
        `
		}}
`

export const RootWrapper = Styled.div`
    ${() => {
			return `
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow:1;
        `
		}}
`

export const OuterBody = Styled.div`
    ${(props) => {
			let theme = props.theme
			return `
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow:auto;
      ${
				props.hidden &&
				`
        overflow-x:hidden;
        `
			}
      
      ${!props.login && !props.fixedFooter && "overflow: hidden;"}
      ${theme.mMX_SM} 
        {
          overflow:unset;
          -webkit-overflow-scrolling: touch;
        }
      }
    `
		}}
`
export const InnerBody = Styled.div`
    ${(props) => {
			let theme = props.theme
			return `
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding:${props.npPadding ? "0" : theme.gLG};
        ${
					!props.fixedFooter &&
					` 
            justify-content: center;
            height: 100%;
        `
				}`
		}}
`

export const LoginBoxStyled = Styled.div`
    ${(props) => {
			let theme = props.theme
			return `
        width:380px;
        align-self: center;
        padding:${theme.gXL};
        background:rgb(${theme.white});
        border-radius:4px;
        box-shadow: 0 1px 20px -4px rgba(${theme.black}, 0.0625);
        border: 1px solid rgba(${theme.black}, 0.0625);
        ${theme.mMX_XS}
        {
            width:90%;
        }
      `
		}}
`

export const HeadlineOne = Styled.h1`
    ${(props) => {
			let theme = props.theme

			let marginB = 0
			if (props.margin) marginB = theme[props.margin]

			let textAlign = "left"
			if (props.center) textAlign = "center"

			let color = `rgba(${theme.secondary})`
			if (props.danger) color = `rgba(${theme.red})`
			return `
        ${theme.fontPrimary};
        ${theme.fLG};
        color:${color};
        margin-bottom:${marginB};
        text-align:${textAlign};
        `
		}}
`

export const LinkStyled = Styled.div`
    ${(props) => {
			let theme = props.theme
			return `
            padding-top:${theme.gMD};
            display: flex;
            justify-content: center;
            color:rgba(${theme.black},0.6);
            >a
            {
                color:rgba(${theme.primary});
                :hover
                {
                    color:rgba(${theme.primary},0.8);
                }
            }
        `
		}}
`

export const LogoHolderLoginStyled = Styled.div`
${(props) => {
	let theme = props.theme
	return `
        display:flex;
        align-items:center;
        justify-content:center;
        padding:${theme.gXL} ${theme.gMD};
    `
}}
`
export const CardWrapperStyled = Styled.section`
${(props) => {
	return `
    display:flex;
    flex-wrap:wrap;
    height:100%;
    justify-content: ${props.center ? `center` : `space-between`};
    flex-direction: ${props.center ? `column` : `row`};;
    align-items: ${props.center ? `center` : `flex-start`};
    ${
			props.fullHeight &&
			`
      height:100%;
    `
		};
  `
}}
`
export const CardStyled = Styled.div`
  ${(props) => {
		let theme = props.theme

		let gap = 0
		switch (props.col) {
			case "4":
				gap = 60
				break
			case "3":
				gap = 40
				break
			case "2":
				gap = 20
				break
			default:
				gap = 0
		}
		gap = gap / Number(props.col)
		let width = Number(props.width) + "%"
		if (gap > 0) {
			width = `calc(${width} - ${gap}px)`
		}

		if (props.max) {
			width = `${props.max}px`
		}

		let bottomMargin = theme.gXL
		if (props.noMargin) {
			bottomMargin = "0"
		}

		return `
    width:${width};

    ${
			props.topMargin &&
			`
      margin-top:${theme.gLG};
    `
		};

    ${theme.mMX_MD}
    {
      width:calc(100% - 1px);
      margin-bottom:${bottomMargin};
      margin-top:unset;
    }
    ${theme.mMX_SM}
    {
      width:calc(100% - 1px);
      margin-top:unset;
      margin-bottom:${bottomMargin};
    }
  `
	}}
`
export const BoxStyled = Styled.div`
  ${(props) => {
		let theme = props.theme
		return `
      border:1px solid rgba(${theme.black}, 0.0625);
      background:rgb(${theme.white});
      height:100%;
      width:100%;
      flex-direction: column;
      display:flex;
      border-radius:4px;
    `
	}}
`
export const FilterMovementStyled = Styled.div`
  ${(props) => {
		let theme = props.theme
		return `
      padding:${theme.gXL} ${theme.gMD} ${theme.gMD} ${theme.gMD};
      >div
      {
        margin-bottom:${theme.gLG};
      }
    `
	}}
`

export const CardHeaderStyled = Styled.div`
  ${(props) => {
		let theme = props.theme
		return `
      border-bottom:1px solid rgba(${theme.black}, 0.0625);
      display:flex;
      justify-content:space-between;
      width:auto;
      background:rgb(${theme.primary});
      border-radius:4px 4px 0 0;
      >h2
      {
        ${theme.fMD};
        padding:${theme.gMD};
        display:flex;
        align-items:center;
        flex-grow:1;
        >svg
        {
          margin-right:${theme.gSM};
          color:rgb(${theme.secondary});
        }
      }
      >span>svg
      {
        height:100%;
        width:40px;
        padding:7px;
        box-sizing: border-box;
        border-radius:0 4px 0 0;
        cursor:pointer;
        :hover
        {
          background:rgba(${theme.black},0.05);
        }
      }
      >span>svg.active
      {
        background:rgba(${theme.black},0.05);
      }
    `
	}}
`

export const CardBodyStyled = Styled.div`
  ${(props) => {
		let theme = props.theme
		return `
      flex-grow:1;
      width:100%;
      ${theme.mMX_MD}
      {
        width:${props.width}px;
      }
      ${
				props.isEmpty &&
				`
        display:flex;
        color:rgba(${theme.red});
        justify-content: center;
        align-items: center;
        min-height:150px;
      `
			};
      ${
				props.height &&
				`
        height:${props.height}px;
        overflow-y:auto;

        ${theme.mMX_MD}
        {
          height:unset;
          overflow-y:unset;
        }
        `
			};
    `
	}}
`

export const CardFooterStyled = Styled.div`
  ${(props) => {
		let theme = props.theme
		return `
      border-top:1px solid rgba(${theme.black}, 0.0625);
      text-align:right;
      
      width:auto;
      ${theme.fDF};
      ${theme.fontPrimary};
      padding:${theme.gMD};
      height:19px;
      >b
      {
        font-weight:700;
        ${theme.fontSecondary};
        padding:0 ${theme.gSM};
      }  
      ${theme.mMX_SM}
      {
        height:unset;
        word-break: break-word;
        white-space: normal;
        >b
        {
          word-break: break-word;
          white-space: normal;
        }  
      }
    `
	}}
`

export const IframeStyled = Styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  min-height: 480px !important;
`

export const TableProductStyled = Styled.div`
${(props) => {
	let theme = props.theme
	return `
    display:flex;
    align-items: center;
    >div
    {
      width:25px;
      height:25px;
      display:flex;
      justify-content:center;
      align-items: center;
      overflow:hidden;
      margin-right:${theme.gSM};
      
      >img
      {
        width:100%;
        height:100%;
      }
    }
  `
}}
`
