import React from "react";
import Styled from "styled-components";
import { formatNumber } from "./Utility";

const UseInput = React.forwardRef(({ config }, ref) => {
  const [show, setShow] = React.useState(false);
  const showHidePassword = () => setShow((prev) => !prev);

  let cls = "eye";
  if (show) cls = "eye slash";

  let typeElement = config.element.type;
  let iconsElement;
  if (config.element.type === "password") {
    iconsElement = (
      <span onClick={showHidePassword} className="showHide">
        <span className={cls}>
          <span></span>
        </span>
      </span>
    );
    if (config.icons) {
      iconsElement = (
        <>
          <span>{config.icons.icon}</span>
          <span onClick={showHidePassword} className="showHide">
            <span className={cls}>
              <span></span>
            </span>
          </span>
        </>
      );
    }

    typeElement = show ? "text" : "password";
  } else {
    if (config.icons) {
      iconsElement = <span>{config.icons.icon}</span>;
    }
  }

  let inputClass = "";
  let isPasswordElement = "";
  if (config.icons) {
    inputClass = `icon ${config.icons.align ? config.icons.align : "left"}`;
  }

  if (config.element.type === "password") {
    isPasswordElement = "password";
  }

  let invalidChars = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ",",
    ".",
    "Backspace",
    "ArrowRight",
    "ArrowLeft",
    "ArrowUp",
    "ArrowDown",
  ];
  const preventNumber = (e) => {
    if (config.element.onKeyDown) config.element.onKeyDown(e);
    if (!invalidChars.includes(e.key) && typeElement === "number")
      e.preventDefault();
  };

  const onChangeHandler = (e) => {
    let value = e.target.value;
    if (typeElement === "number") value = formatNumber(value);
    config.element.onChange(value);
  };
  return (
    <InputStyled
      className={`${
        config.className
      } input ${inputClass} ${isPasswordElement} ${
        !config.valid && config.touched ? "error" : ""
      }`}
    >
      {iconsElement}
      {typeElement === "number" ? (
        <input
          {...config.element}
          onChange={onChangeHandler}
          autoComplete="off"
          ref={ref}
          type={typeElement === "number" ? "text" : typeElement}
          pattern={typeElement === "number" ? "[0-9]{1,4}(.[0-9]{2})?" : ""}
          className={`${config.value ? "focused" : ""}`}
          defaultValue={config.value}
          onKeyDown={preventNumber}
        />
      ) : (
        <input
          {...config.element}
          onChange={onChangeHandler}
          autoComplete="off"
          ref={ref}
          type={typeElement === "number" ? "text" : typeElement}
          className={`${config.value ? "focused" : ""}`}
          defaultValue={config.value}
          onKeyDown={preventNumber}
        />
      )}

      <label htmlFor={config.element.name} className="placeholder">
        {config.label}
      </label>
    </InputStyled>
  );
});

export default React.memo(UseInput);

const InputStyled = Styled.div`
    ${(props) => {
      let theme = props.theme;
      return `
            display:block;
            position: relative;
            margin-bottom:5px;

            >span{
                position:absolute;
                z-index:2;
                height:calc(100% - 2px);
                border-radius:0 4px 4px 0;
                width:40px;
                pointer-events: none;
                display:flex;
                justify-content:center;
                align-items:center;

                color:rgba(${theme.black},0.4);
                background:transparent);
            }

            .showHide{
                pointer-events: unset!important;
                cursor:pointer;
                position: absolute;
                top: 1px;
                right: 1px;
                left:unset!important;
            }

            .eye{
                width: 11px;
                height: 11px;
                border: solid 1px rgba(${theme.black},0.4);
                border-radius: 75% 15%;
                position: relative;
                transform: rotate(45deg);
                display: flex;
                justify-content: center;
                align-items: center;

                

                ::before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 3px;
                    height: 3px;
                    border: 1px solid rgba(${theme.black},0.4);
                    border-radius: 50%;
                }
            }

            .eye.slash > span {
                margin-top: 0;
                margin-left: -3px;
                margin-right: -3px;
                margin-bottom: 1px;
                width: 17px;
                border: 1px solid rgba(${theme.black},0.4);
                z-index: 1;
              }

            >input{
                width:calc(100% - 22px);
                border: none;
                -webkit-appearance: none;
                -ms-accelerator: none;
                -moz-appearance: none;
                appearance: none;
                resize: none;
                outline: none;
                border-radius:4px;
                padding:0 ${theme.gMD};
                min-height:50px;
                display:flex;
                justify-content:center;
                align-items:center;

                border:1px solid rgba(${theme.black},0.23);
            }
            
            .placeholder {
                pointer-events: none;
                position: absolute;
                top: calc(42px - 5px);
                left: 11px;

                -webkit-transform: translateY(-100%);
                transform: translateY(-100%);

                transition: top .2s ease,font-size .2s ease,color .2s ease;

                height:20px;
                background:rgb(${theme.white});
                width:calc(100% - 42px);
                color:rgba(${theme.black},0.4);

                ::after {
                    height:1px;
                    background:rgb(${theme.white});
                    content: "";
                    left: -5px;
                    position: absolute;
                    top: calc(50%);
                    width: 100%;
                    z-index: -1;
                    border:0;
                    padding:0 5px;
                }

            }
            .focused+.placeholder, input.focused+.placeholder, input:focus+.placeholder {
                top: 10px!important;
                width:auto;
                background:transparent;
                font-size:14px;
                color:rgba(${theme.black},0.4);
            }


        `;
    }}
`;
