import React from "react"
import { Link } from "react-router-dom"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import EmailIcon from "@mui/icons-material/Email"

import {
	getError,
	selectNextOnEnter,
	UpdateInputChange,
	updateObject,
} from "../shared/Utility"
import { HeadlineOne, LinkStyled } from "../../theme"
import { AuthProvider } from "../../server-requests/authContextProvider"
import FormHooks from "../shared/formHooks"
import { SendPasswordResetEmail } from "../../firebase"
import layout from "../../layout/layout"

const ForgotPassword = () => {
	const submitRef = React.useRef()
	const { snackbar } = React.useContext(AuthProvider)
	const [isLoading, setIsLoading] = React.useState(false)
	const [formInputs, setFormInputs] = React.useState({
		email: {
			label: "Email",
			icons: {
				icon: <EmailIcon />,
				align: "left", // left or right
			},
			validation: {
				required: true,
			},
			valid: false,
			touched: false,
			inputRef: null,
			value: "",
			element: {
				type: "email",
				placeholder: "Seu email",
				autoComplete: "Off",
				onKeyDown: (e) => selectNextOnEnter(e, submitRef),
			},
		},
		submit: {
			label: "Enviar",
			icons: {
				loading: true,
				icon: <LockOpenIcon />,
			},
			validation: {
				required: true,
			},
			inputRef: submitRef,
			valid: false,
			element: {
				type: "button",
				display: "full",
			},
		},
	})

	const inputChangeHandler = (value, key) =>
		setFormInputs(UpdateInputChange(formInputs, value, key))

	const forgotPasswordSubmitHandler = (e) => {
		if (formInputs.submit.valid) {
			let email = formInputs.email.value
			e.preventDefault()
			setIsLoading(true)
			setFormInputs((prev) =>
				updateObject(prev, {
					submit: updateObject(prev.submit, {
						valid: false,
					}),
				})
			)
			SendPasswordResetEmail(email)
				.then(() => {
					snackbar(`Foi enviado um e-mail para ${email}.`, "success", "push")
					setIsLoading(false)
				})
				.catch((status) => {
					setIsLoading(false)
					snackbar(getError(status))
				})
		}
	}
	return (
		<>
			<HeadlineOne center margin="gXL">
				Esqueci-me da palavra-passe
			</HeadlineOne>

			<FormHooks
				formInputs={formInputs}
				inputChange={inputChangeHandler}
				onSubmit={forgotPasswordSubmitHandler}
				loading={isLoading}
			/>

			<LinkStyled>
				Voltar para &nbsp;<Link to="/login">Entrar</Link>.
			</LinkStyled>
		</>
	)
}

export default layout(ForgotPassword, "others")
