import React from "react";
import { useNavigate } from "react-router-dom";
import Styled from "styled-components";

import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { updateObject } from "./Utility";
import { SignOutUser } from "../../firebase";
import { AuthProvider } from "../../server-requests/authContextProvider";

const Icons = {
  success: CheckCircleOutlineIcon,
  warning: ReportGmailerrorredIcon,
  errors: ErrorOutlineIcon,
  info: InfoIcon,
};

const UseSnackbar = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { setAuthLoading, setFirebaseUser, setUser, setIsVerified } = React.useContext(AuthProvider);

  const [data, setData] = React.useState({
    isOpen: false,
    message: "",
    type: "errors",
    isNotification: false,
    isLogout: false,
    isPush: false,
  });

  const Close = () => {
    setData((prev) => updateObject(prev, { open: false }));
    if (data.isLogout) {
      SignOutUser().then(() => {
        setAuthLoading(true);
        setFirebaseUser(null);
        setUser(null)
        setIsVerified(true)
				navigate('/login')
			});
    }
      
    if (data.isPush & !data.isLogout) {
      navigate("/login")
    };
  };

  React.useImperativeHandle(ref, () => ({
    Open(text, types = "errors", action) {
      setData((prev) =>
        updateObject(prev, {
          open: true,
          message: text,
          type: types,
          isNotification: action === "notification",
          isLogout: action === "logout",
          isPush: action === "logout" || action === "push",
        })
      );
    },
  }));
  const Icon = Icons[data.type];

  if (!data.open) return null;
  return (
    <SnackbarStyled
      anchorOrigin={{
        vertical: "top",
        horizontal: data.isNotification ? "right" : "center",
      }}
      open={data.open}
      onClose={Close}
      autoHideDuration={data.isNotification ? 4000 : 3000}
    >
      <SnackbarContent
        className={data.type}
        message={
          <>
            <Icon />
            <label>{data.message}</label>
            <IconButton
              key="close"
              aria-label="Close"
              onClick={Close}
              size="small"
            >
              <CloseIcon fontSize="medium" size="small" />
            </IconButton>
          </>
        }
      />
    </SnackbarStyled>
  );
});
export default UseSnackbar;

const SnackbarStyled = Styled(Snackbar)`
  ${(props) => {
    let current = props.theme;
    return `
    >div{
      box-shadow: 0 3px 5px 2px rgba(${current.black}, 0.3)!important;
      border-radius: 4px!important;
      border: 0;
      min-width:unset!important;
      color: rgb(${current.white});
      ${
        props.anchorOrigin.horizontal === "right" &&
        `
      max-width:280px!important;
      `
      };
      
      >div{
        display:flex;
        align-items:center;
        padding:0!important;

        >label{
          margin-left: 10px;
          flex-grow:1;
          margin-bottom: 0;
        }
      }
    }
      .errors{
        background: rgb(${current.red});
      }
      .success{
        background: rgb(${current.green});
      }
      .warning{
        background: rgb(${current.orange});
      }
      .info{
        background: rgb(${current.cOne});
      }
  `;
  }}
`;
