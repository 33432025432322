import React from "react";

import Footer from "../components/shared/Footer";
import { RootWrapper, OuterBody, InnerBody, LoginBoxStyled } from "../theme";
import Branding from "../components/shared/branding";

const Others = (props) => {
  return (
    <RootWrapper>
      <OuterBody login>
        <InnerBody>
          <Branding />
          <LoginBoxStyled>{props.children}</LoginBoxStyled>
          <Footer login />
        </InnerBody>
      </OuterBody>
    </RootWrapper>
  );
};

export default Others;
