import React from "react";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { HeadlineOne, LinkStyled } from "../../theme";
import { AuthProvider } from "../../server-requests/authContextProvider";
import {
  getError,
  selectNextOnEnter,
  UpdateInputChange,
  updateObject,
} from "../shared/Utility";
import FormHooks from "../shared/formHooks";
import { SignIn } from "../../firebase";
import layout from "../../layout/layout";

const Login = () => {
  const passwordRef = React.useRef();
  const submitRef = React.useRef();
  const { snackbar, setAuthLoading } = React.useContext(AuthProvider);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formInputs, setFormInputs] = React.useState({
    email: {
      label: "Email",
      icons: {
        icon: <EmailIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      inputRef: null,
      value: "",
      element: {
        type: "email",
        placeholder: "Seu email",
        onKeyDown: (e) => selectNextOnEnter(e, passwordRef),
      },
    },
    password: {
      label: "Palavra-passe",
      icons: {
        icon: <LockIcon />,
        align: "left", // left or right
      },
      validation: {
        required: true,
        min: 6,
      },
      inputRef: passwordRef,
      valid: false,
      touched: false,
      value: "",
      element: {
        type: "password",
        placeholder: "Sua Palavra-passe",
        onKeyDown: (e) => selectNextOnEnter(e, submitRef),
      },
    },
    submit: {
      label: "Entrar",
      icons: {
        loading: true,
        icon: <ExitToAppIcon />,
      },
      validation: {
        required: true,
      },
      inputRef: submitRef,
      valid: false,
      element: {
        type: "button",
        display: "full",
      },
    },
  });

  const inputChangeHandler = (value, key) =>
    setFormInputs(UpdateInputChange(formInputs, value, key));

  const loginSubmitHandler = (e) => {
    if (formInputs.submit.valid) {
      e.preventDefault();
      setIsLoading(true);
      setFormInputs((prev) =>
        updateObject(prev, {
          submit: updateObject(prev.submit, {
            valid: false,
          }),
        })
      );
      SignIn(formInputs.email.value, formInputs.password.value)
        .then(() => setAuthLoading(true))
        .catch(() => {
          setAuthLoading(false);
          setIsLoading(false);
          snackbar(getError("errorLogin"));
        });
    }
  };
  return (
    <>
      <HeadlineOne center margin="gXL">
        Entrar
      </HeadlineOne>
      <FormHooks
        formInputs={formInputs}
        inputChange={inputChangeHandler}
        onSubmit={loginSubmitHandler}
        loading={isLoading}
      />
      <LinkStyled>
        <Link to="/forgot-password">Esqueceu a sua palavra-passe?</Link>
      </LinkStyled>
    </>
  );
};

export default layout(Login, "login");
