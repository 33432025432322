import React from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import PropTypes from "prop-types";

import LogoURL from "../../logo.png";

const Logo = ({ branding, linkTo, logoHeight = 40, align = "left" }) => {
  let brandName = (
    <BrandNameStyled>
      QR<span>Koin</span>
      <span className="slogan">CASHLESS PAYMENTS</span>
    </BrandNameStyled>
  );
  let brandElement = (
    <BrandNameLogoStyled align={align}>
      <img src={LogoURL} alt="QRKoin" /> {brandName}
    </BrandNameLogoStyled>
  );

  if (branding === "name") {
    brandElement = brandName;
  } else if (branding === "logo") {
    brandElement = <img src={LogoURL} alt="QRKoin" />;
  }

  let logoElement = brandElement;
  if (linkTo) logoElement = <Link to={linkTo}>{brandElement}</Link>;

  return <LogoStyled logoHeight={logoHeight}>{logoElement}</LogoStyled>;
};

Logo.propTypes = {
  linkTo: PropTypes.string,
  branding: PropTypes.string,
  logoHeight: PropTypes.number,
  align: PropTypes.string,
};

export default Logo;

const BrandNameLogoStyled = Styled.div`
${(props) => {
  let theme = props.theme;
  let direction = props.align === "center" ? "column" : "row";
  let marginClass =
    props.align === "center"
      ? `margin-bottom:${theme.gXL}`
      : `margin-right:${theme.gSM}`;
  return `
      display:flex;
      flex-direction:${direction};
      align-items: center;
      img{
        ${marginClass};
      }
    `;
}}
`;

const BrandNameStyled = Styled.div`
${(props) => {
  let theme = props.theme;
  return `
    ${theme.fXXXL};
    color:rgba(${theme.secondary});
    ${theme.fontPrimary};
    text-align:center;
    margin-top: 13px;
    >span{
      color:rgba(${theme.primary});
    }
    .slogan{
      display:block;
      ${theme.fSM};
      color:rgba(${theme.secondary});
      ${theme.fontMono};
      margin-top:5px;
      font-weight:700;
      // opacity:0.9;
    }
    `;
}}
`;

const LogoStyled = Styled.h1`
    ${(props) => {
      let theme = props.theme;
      return `
        >a{
          display:flex;
          align-items: center;
          ${theme.fontPrimary};
          ${theme.fXL};
        }
        .both{
          margin-right:${theme.gMD};
        }
        img{
          width:auto;
          height:${props.logoHeight}px;
        }
       
        `;
    }}
`;
