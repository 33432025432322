import React from "react"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import SearchIcon from "@mui/icons-material/Search"

import MbWay from "../../mbway.png"
import QRKoin from "../../logo.png"
import layout from "../../layout/layout"

import {
	BoxStyled,
	CardBodyStyled,
	CardFooterStyled,
	CardHeaderStyled,
	CardStyled,
	CardWrapperStyled,
} from "../../theme"
import {
	GetFirstDayOfMonth,
	getError,
	getMonthName,
	getDays,
	getHours,
	GetLastDayOfMonth,
	formatWithEuro,
	checkIfRefundable,
	dateFormatter,
	updateObject,
	formatAmount,
} from "../shared/Utility"
import { AuthProvider } from "../../server-requests/authContextProvider"
import { Get } from "../../server-requests/serverRequest"
import Filter from "./Filter"
import { LoadingComponent } from "../shared/Loading"
import Charts from "./Charts"
import UseSwitchView from "../shared/useSwitchView"
import Refund from "./Refund"
import UseButton from "../shared/useButton"
import UseTable from "../shared/useTable"
import useWindowSize, { useSizeWithChild } from "../shared/useWindowSize"
import UseExportToExcel from "../shared/useExportToExcel"

const getTotalAmount = (list) => {
	let toReturn = 0
	for (let item of list) {
		if (!item.refunded) toReturn += item.amount
	}
	return formatAmount(toReturn)
}

const getTotalCommission = (list) => {
	let totalToReturn = 0
	for (let item of list) {
		if (!item.mbwayId && !item.refunded) totalToReturn += item.commission
	}
	return formatAmount(totalToReturn)
}

const Reports = () => {
	const mainDivRef = React.useRef(null)
	const refundRef = React.useRef(null)
	const { snackbar } = React.useContext(AuthProvider)

	const { width: windowsWidth } = useWindowSize()
	let isSmallDevice = windowsWidth < 991
	const wrapDiv = useSizeWithChild(mainDivRef)
	let tableHeight, tableWidth
	let width
	if (wrapDiv.height && wrapDiv.width) {
		tableHeight = wrapDiv.height - 5
		tableWidth = "100%"
		width = wrapDiv.width
	}
	if (isSmallDevice) {
		if (wrapDiv.width) tableWidth = wrapDiv.width + 41
		tableHeight = 400
	}

	const [data, setData] = React.useState({
		view: "list",
		reports: {
			reload: true,
			isLoading: true,
			list: [],
			fullList: [],
			total: 0,
			totalCommission: 0,
			locations: [],
			devices: [],
		},
		filter: {
			reload: false,
			from: GetFirstDayOfMonth(new Date()),
			to: GetLastDayOfMonth(new Date()),
			process: "all",
			payment: "all",
			location: "all",
			device: "all",
			refund: "all",
		},
		chart: {
			reload: false,
			total: 0,
			list: [],
		},
	})

	// get reports list from server
	React.useEffect(() => {
		let isMounted = true
		if (data.view === "list" && data.reports.reload) {
			Get("/movements/list", {
				from: data.filter.from,
				to: data.filter.to,
			})
				.then((items) => {
					if (isMounted) {
						let formattedList = items
						let location = [
							{
								label: "Todos",
								value: "all",
							},
						]
						let device = [
							{
								label: "Todos",
								value: "all",
							},
						]
						for (let event of formattedList) {
							let isExistLocation = location.some(
								(item) => item.label === event.price.device.location.name
							)
							let isExistDevice = device.some(
								(item) => item.label === event.price.device.name
							)
							if (!isExistDevice)
								device.push({
									label: event.price.device.name,
									value: event.price.device.name,
								})
							if (!isExistLocation)
								location.push({
									label: event.price.device.location.name,
									value: event.price.device.location.name,
								})
						}

						setData((prev) => {
							let newObject = updateObject(prev, {
								reports: updateObject(prev.reports, {
									list: formattedList,
									fullList: formattedList,
									isLoading: false,
									reload: false,
									total: getTotalAmount(items),
									totalCommission: getTotalCommission(items),
									locations: location,
									devices: device,
								}),
							})
							if (data.filter.processed !== "all")
								newObject = updateObject(newObject, {
									filter: updateObject(prev.filter, {
										reload: true,
									}),
								})
							return newObject
						})
					}
				})
				.catch((status) => {
					setData((prev) =>
						updateObject(prev, {
							reports: updateObject(prev.reports, {
								isLoading: false,
								reload: false,
							}),
						})
					)
					snackbar(getError(status))
				})
		}
		return () => (isMounted = false)
	}, [data.reports.reload, data.view, data.filter, snackbar])

	// get graphs from server
	React.useEffect(() => {
		let isMounted = true
		if (data.view === "chart" && data.chart.reload) {
			Get("/graphs", {
				from: data.filter.from,
				to: data.filter.to,
			})
				.then((graph) => {
					if (isMounted) {
						if (graph.separator === "months")
							graph.list = getMonthName(graph.list)
						if (graph.separator === "days") graph.list = getDays(graph.list)
						if (graph.separator === "hours") graph.list = getHours(graph.list)

						let total = graph.list.reduce(
							(total, item) => total + item.yAxis,
							0
						)

						setData((prev) =>
							updateObject(prev, {
								chart: updateObject(prev.chart, {
									list: graph,
									total: total,
									reload: false,
								}),
							})
						)
					}
				})
				.catch((status) => {
					setData((prev) =>
						updateObject(prev, {
							chart: updateObject(prev.chart, {
								reload: false,
							}),
						})
					)
					snackbar(getError(status))
				})
		}
		return () => (isMounted = false)
	}, [data.chart.reload, data.view, data.filter, snackbar])

	// filter reports list by processed
	React.useEffect(() => {
		if (data.filter.reload && data.reports.fullList.length > 0) {
			let filteredList = [...data.reports.fullList]

			// search by refund
			if (data.filter.refund === "refunded")
				filteredList = filteredList.filter((item) => item.refunded)
			// search by not processed
			if (data.filter.refund === "not-refunded")
				filteredList = filteredList.filter((item) => !item.refunded)
			// search by processed
			if (data.filter.process === "processed")
				filteredList = filteredList.filter((item) => item.processed)
			// search by not processed
			if (data.filter.process === "not-processed")
				filteredList = filteredList.filter((item) => !item.processed)
			// search by payment qrkoin
			if (data.filter.payment === "qrkoin")
				filteredList = filteredList.filter((item) => !item.mbwayId)
			// search by payment mbway
			if (data.filter.payment === "mbway")
				filteredList = filteredList.filter((item) => item.mbwayId)
			// search by payment location
			if (data.filter.location !== "all")
				filteredList = filteredList.filter(
					(item) => item.price.device.location.name === data.filter.location
				)
			// search by device
			if (data.filter.device !== "all")
				filteredList = filteredList.filter(
					(item) => item.price.device.name === data.filter.device
				)

			setData((prev) =>
				updateObject(prev, {
					reports: updateObject(prev.reports, {
						list: filteredList,
						isLoading: false,
						total: getTotalAmount(filteredList),
						totalCommission: getTotalCommission(filteredList),
					}),
					filter: updateObject(prev.filter, {
						reload: false,
					}),
				})
			)
		}
	}, [data.filter, data.reports.fullList, data.filter.reload])

	const reportFilterHandler = (filterBy, type) => {
		if (data.view === "list") {
			setData((prev) => {
				let objectUpdate = updateObject(prev, {
					reports: updateObject(prev.reports, {
						isLoading: true,
					}),
					filter: updateObject(prev.filter, {
						reload: true,
						from: filterBy.from,
						to: filterBy.to,
						process: filterBy.process,
						refund: filterBy.refund,
						payment: filterBy.payment,
						location: filterBy.location,
						device: filterBy.device,
					}),
				})
				if (type === "from" || type === "to")
					objectUpdate = updateObject(objectUpdate, {
						reports: updateObject(objectUpdate.reports, {
							reload: true,
						}),
						filter: updateObject(objectUpdate.filter, {
							reload: false,
						}),
					})
				return objectUpdate
			})
		} else {
			setData((prev) => {
				let objectUpdate = updateObject(prev, {
					filter: updateObject(prev.filter, {
						reload: true,
						from: filterBy.from,
						to: filterBy.to,
						process: filterBy.process,
					}),
				})
				if (type !== "processed")
					objectUpdate = updateObject(objectUpdate, {
						chart: updateObject(prev.reports, {
							reload: true,
						}),
						filter: updateObject(objectUpdate.filter, {
							reload: false,
						}),
					})
				return objectUpdate
			})
		}
	}

	const refundClickHandler = (row) => refundRef.current.Open(row)

	let COLUMNS = [
		{
			id: "paid",
			label: "Data/Hora",
			searchBy: false,
			orderBy: true,
			align: "center",
			format: (date) => dateFormatter(date),
		},
		{
			id: "price.device.location.name",
			label: "Localização",
			searchBy: false,
			orderBy: true,
			align: "center",
		},
		{
			id: "price.device.name",
			label: "Dispositivo",
			searchBy: false,
			orderBy: true,
			align: "center",
		},
		{
			id: "description",
			label: "Descrição",
			searchBy: false,
			orderBy: true,
			align: "center",
		},
		{
			id: "amount",
			label: "Preço",
			searchBy: false,
			orderBy: true,
			align: "center",
			format: (item) => formatAmount(item),
		},
		{
			id: "mbwayId",
			label: "Meio de pagamento",
			searchBy: false,
			orderBy: false,
			align: "center",
			format: (item) =>
				item ? (
					<img src={MbWay} style={{ width: 40 }} alt="MBWay" />
				) : (
					<img src={QRKoin} style={{ width: 40 }} alt="QRKoin" />
				),
		},
		{
			id: "commission",
			label: "Comissão\nQRKoin",
			searchBy: false,
			orderBy: false,
			align: "center",
			format: (item, row) => {
				let toReturn = formatAmount(item)
				if (row.mbwayId || row.refunded) toReturn = "-"
				return toReturn
			},
		},
		{
			id: "processed",
			label: "Processado",
			searchBy: false,
			orderBy: false,
			align: "center",
			format: (date) => (date ? dateFormatter(date, "date") : "Não processado"),
		},
		{
			id: "refunded",
			label: "Reembolso",
			searchBy: false,
			orderBy: false,
			align: "center",
			format: (date, row, report) => {
				if (date) return dateFormatter(date, "date")
				if (report) return "-"
				let btn = (
					<UseButton
						clicked={() => refundClickHandler(row)}
						value="Efetuar Reembolso"
						type="button"
						skin="danger"
						display="full"
						size="md"
					/>
				)
				let btnElement = "-"
				if (!row.mbwayId) {
					if (!row.processed) btnElement = btn
				} else {
					// 5 days maximum that can be refund.
					let isRefundable = checkIfRefundable(row.paid, 5)
					if (isRefundable && row.mbwayMerchantTransactionId) btnElement = btn
				}
				return btnElement
			},
		},
	]

	let reportsElement = null
	if (!data.reports.isLoading) {
		if (data.reports.list.length > 0 && tableHeight) {
			reportsElement = (
				<UseTable
					tableWidth={tableWidth}
					tableHeight={tableHeight}
					data={data.reports.list}
					columns={COLUMNS}
					headerHeight={54.77}
					rowHeight={68.5}
					paginationHeight={48}
				/>
			)
		} else {
			reportsElement = `Não existem dados para relatórios.`
		}
	} else {
		reportsElement = <LoadingComponent />
	}

	let footerElement = null
	if (data.reports.list.length > 0)
		footerElement = (
			<>
				Tem <b>{data.reports.list.length}</b> movimentos com um montante total
				de <b>{data.reports.total}</b>, de{" "}
				<b>{dateFormatter(data.filter.from, "date")}</b> a{" "}
				<b>{dateFormatter(data.filter.to, "date")}.</b> com a{" "}
				<b>Comissão QRKoin {data.reports.totalCommission}.</b>
			</>
		)

	const switchViewHandler = (clickedOn) => {
		if (clickedOn === "list") {
			setData((prev) =>
				updateObject(prev, {
					view: clickedOn,
					reports: updateObject(prev.reports, {
						reload: true,
					}),
				})
			)
		} else {
			setData((prev) =>
				updateObject(prev, {
					view: clickedOn,
					chart: updateObject(prev.chart, {
						reload: true,
					}),
				})
			)
		}
	}

	const refundSuccessHandler = React.useCallback(() => {
		let message = "Reembolso efectuado com sucesso!"
		snackbar(message, "success")
		setData((prev) =>
			updateObject(prev, {
				reports: updateObject(prev.reports, {
					isLoading: true,
					reload: true,
				}),
			})
		)
	}, [snackbar])

	const refundNotSuccessHandler = React.useCallback(() => {
		setData((prev) =>
			updateObject(prev, {
				reports: updateObject(prev.reports, {
					isLoading: true,
					reload: true,
				}),
			})
		)
	}, [])

	return (
		<CardWrapperStyled ref={mainDivRef}>
			<CardStyled width="20" col="2">
				<BoxStyled>
					<CardHeaderStyled>
						<h2>
							<SearchIcon />
							Filtrar por
						</h2>
					</CardHeaderStyled>
					<CardBodyStyled height={tableHeight + 44}>
						<Filter
							filter={reportFilterHandler}
							locations={data.reports.locations}
							devices={data.reports.devices}
							filterBy={data.filter}
							view={data.view}
						/>
					</CardBodyStyled>
				</BoxStyled>
			</CardStyled>
			<CardStyled width="80" col="2">
				<BoxStyled>
					<CardHeaderStyled>
						<h2>
							<TrendingUpIcon />
							Relatório de venda
						</h2>
						<UseSwitchView
							change={switchViewHandler}
							current={data.view}
							isSmallDevice={isSmallDevice}
						/>
						<UseExportToExcel filter={data.filter} rows={data.reports.list} columns={COLUMNS} />
					</CardHeaderStyled>
					{data.view === "list" && (
						<>
							<CardBodyStyled
								height={tableHeight}
								width={width - 30}
								isEmpty={data.reports.list.length === 0}
							>
								{reportsElement}
							</CardBodyStyled>
							<CardFooterStyled>{footerElement}</CardFooterStyled>
						</>
					)}
					{data.view === "chart" && (
						<>
							<CardBodyStyled
								height={tableHeight}
								width={mainDivRef.current.offsetWidth}
								isEmpty={data.chart.list.length === 0}
							>
								<Charts
									data={data.chart.list}
									isSmallDevice={isSmallDevice}
									height={tableHeight}
								/>
							</CardBodyStyled>
							<CardFooterStyled
								style={{
									textAlign: "right",
									fontSize: "20px",
									marginRight: "20px",
									fontWeight: "bold",
								}}
							>
								Total: {formatWithEuro(data.chart.total)}
							</CardFooterStyled>
						</>
					)}
				</BoxStyled>
			</CardStyled>
			<Refund
				ref={refundRef}
				success={refundSuccessHandler}
				reload={refundNotSuccessHandler}
			/>
		</CardWrapperStyled>
	)
}

export default layout(Reports)
