import axios from "axios"

import { Log } from "../components/shared/Utility"
import { getFirebaseToken } from "../firebase"

axios.interceptors.request.use(
	async (config) => {
		await getFirebaseToken()
			.then((token) => {
				Log("Token:" + token)
				config.timeout = 10000
				config.baseURL = process.env.REACT_APP_SERVER_ADDRESS
				config.headers = { Authorization: "Bearer " + token }
			})
			.catch((err) => Promise.reject(err))
		return config
	},
	(err) => Promise.reject(err)
)

export const Get = (url, params) => {
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				params,
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error.response ? error.response.status : 503))
	})
}

export const Post = (url, data, params) => {
	return new Promise((resolve, reject) => {
		axios
			.post(url, data, {
				params,
			})
			.then((response) => resolve(response.data))
			.catch((error) => {
				reject(error.response ? error.response.status : 503)
			})
	})
}

export const Put = (url, data, params) => {
	return new Promise((resolve, reject) => {
		axios
			.put(url, data, {
				params,
			})
			.then((response) => resolve(response.data))
			.catch((error) => reject(error.response ? error.response.status : 503))
	})
}
