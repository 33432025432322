import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from '@mui/icons-material/History';

import { dateFormatter, updateObject } from "../shared/Utility";
import UseButton from "../shared/useButton";
import { DialogBody } from "../shared/useDialog";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Conformation = React.forwardRef(({ download }, ref) => {

  const [data, setData] = React.useState({
    isOpen: false,
    filter: null,
    clicked: false,
  });

  const closeDialog = () => {
    setData((prev) =>
      updateObject(prev, {
        isOpen: false,
      })
    );
  };

  // in case of add edit and delete
  React.useImperativeHandle(ref, () => ({
    Open(current) {
      setData((prev) => {
        let obj = updateObject(prev, {
          filter: current,
          isOpen: true,
        });

        return obj;
      });
    },
  }));

  const downloadHandler = () => {
    closeDialog()
    download()
  };

  if (!data.isOpen) return null;

  return (
    <DialogBody
      title="Filtrar"
      size="sm"
      type={data.type}
      close={closeDialog}
    >
      <div className="body">
        <p style={{ margin: "10px 0" }}>
        O filtro atual aplicado é a partir de <b>{dateFormatter(data.filter.from, "date")}</b> até <b>{dateFormatter(data.filter.to, "date")}</b>, Localização <b>{data.filter.location}</b>, Dispositivo <b>{data.filter.device}</b>, Método de pagamento é <b>{data.filter.payment}</b>, processado <b>{data.filter.process}</b> e reembolsado <b>{data.filter.refund}</b>. Download?
        </p>
      </div>
      <div className="footer">
        <UseButton
          clicked={closeDialog}
          value="Fechar"
          type="button"
          skin="default"
          size="md"
          display="default"
          icons={{ icon: <CloseIcon /> }}
        />

        <UseButton
          clicked={downloadHandler}
          value="Download"
          type="button"
          skin="primary"
          size="md"
          style={{ marginLeft: "auto" }}
          icons={{ icon: <FileDownloadIcon /> }}
        />
      </div>
    </DialogBody>
  );
});

export default Conformation;
