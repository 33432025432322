import React from "react";
import Styled from "styled-components";
import PropTypes from "prop-types";

import Logo from "./Logo";
import { AuthProvider } from "../../server-requests/authContextProvider";
import UseUserInformation from "./useUserInformation";
import Navigation from "./Navigation";
import useWindowSize from "./useWindowSize";

const Header = (props) => {
  const { firebaseUser } = React.useContext(AuthProvider);
  const { width } = useWindowSize();
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const openMenuHandler = React.useCallback(
    () => setIsOpenMenu((prev) => !prev),
    []
  );

  let userInfoElement = null;
  let sandwichMenuElement = null;

  if (width < 991)
    sandwichMenuElement = (
      <SandwichMenu isOpen={isOpenMenu} onClick={openMenuHandler}>
        <span></span>
        <span></span>
        <span></span>
      </SandwichMenu>
    );
  let menuElement = null;
  if (firebaseUser) {
    userInfoElement = <UseUserInformation isWhite={props.isWhite} />;
    menuElement = (
      <Navigation
        isOpen={isOpenMenu}
        isWhite={props.isWhite}
        isLanding={props.isLanding}
      />
    );
  } else {
    menuElement = (
      <Navigation
        isOpen={isOpenMenu}
        isWhite={props.isWhite}
        isLanding={props.isLanding}
      />
    );
  }

  let headerElement = (
    <HeaderStyled isWhite={props.isWhite}>
      <Blanket isOpen={isOpenMenu} onClick={openMenuHandler} />
      {sandwichMenuElement}
      <Logo linkTo="/" align="left" branding="both" />
      {menuElement}
      {userInfoElement}
    </HeaderStyled>
  );

  if (props.isLanding)
    headerElement = (
      <HeaderStyled isWhite={props.isWhite}>
        <Blanket isOpen={isOpenMenu} onClick={openMenuHandler} />
        <div className="wrapper">
          {sandwichMenuElement}
          <Logo linkTo="/" align="left" branding="both" />
          {menuElement}
          {userInfoElement}
        </div>
      </HeaderStyled>
    );

  return headerElement;
};

Header.propTypes = {
  isWhite: PropTypes.bool,
};

export default Header;

const Blanket = Styled.div`
  ${(props) => {
    let theme = props.theme;
    return `
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    display: none;
    background: rgba(${theme.black}, 0.5);
    ${
      props.isOpen &&
      `
    display: block;
    `
    }
  `;
  }}
`;

const HeaderStyled = Styled.div`
  ${(props) => {
    let theme = props.theme;

    let hBG = !props.isWhite ? `rgba(${theme.primary})` : "unset";
    let hBorder = `1px solid ${
      props.isWhite ? `rgba(${theme.black},0.0625)` : "unset"
    }`;
    return `
        ${theme.headerFooterHeight};
        box-sizing: border-box;
        display:flex;
        justify-content: space-between;
        align-items:center;
        padding-right:${theme.gLG};
        padding-left:${theme.gLG};
        border-bottom:${hBorder};
        background:${hBG};
        .wrapper{
          width:1050px;
          margin:0 auto;
          box-sizing: border-box;
          display:flex;
          justify-content: space-between;
          align-items:center;
        }
        ${theme.mMX_LG} {
          .wrapper{
            width:90%;
          }
        }
        >h1{
          ${theme.fLG};
          color:rgba(${theme.primary});
        }
    `;
  }}
`;
const SandwichMenu = Styled.div`
  ${(props) => {
    let theme = props.theme;
    return `
    flex-direction: column;
    width: 30px;
    height: 30px;
    display:flex;
    justify-content: space-around;
    cursor: pointer;
    z-index: 12;
    margin-right: ${theme.gSM};
      >span{
        height:2px;
        background:rgb(${theme.primary})!important;
        transition: 0.2s;
      }
      >span:nth-child(2){
        width:25px;
      }
      ${
        props.isOpen &&
        `
        display: flex;
        > span:first-child {
          display: none;
        }
        > span {
          transform: rotate(-45deg) translateY(10px);
          width: 30px !important;
          background: rgb(${theme.white})!important;
          margin-left: -${theme.gMD};
        }
        > span:last-child {
          transform: rotate(45deg) translateY(-10px);
        }
      `
      }
    `;
  }};
`;
