import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

import { formatWithEuro } from "../shared/Utility";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <TooltipStyled>
        <b className="title">{`${label}`}</b>
        {payload.length > 0 &&
          payload.map((item, index) => (
            <span
              key={index}
              style={{ color: item.fill }}
              data-translate="0042,0043"
            >
              Quantia :<span>{formatWithEuro(item.value)}</span>
            </span>
          ))}
      </TooltipStyled>
    );
  }
  return null;
};

const Charts = ({ data, isSmallDevice, height }) => {
  return (
    <ResponsiveContainer
      aspect={isSmallDevice ? undefined : 2}
      maxHeight={height - 30}
    >
      <AreaChart
        data={data.list}
        margin={{
          top: 20,
          right: 20,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="10%" stopColor="#1078F0" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#1078F033" stopOpacity={0.7} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="xAxis" />
        <YAxis label="€" />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="yAxis"
          stroke="#1078F0"
          pointHoverRadius={10}
          strokeWidth={2}
          fill="url(#colorUv)"
          activeDot={{ r: 7 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Charts;

export const TooltipStyled = styled.div`
  ${(props) => {
    let current = props.theme;
    return `
        background: rgb(${current.white});
        padding: ${current.gMD};
        border: 1px solid rgba(${current.cThree},0.2);
        display: flex;
        flex-flow: column;
        .title {
            margin-bottom: ${current.gMD};
            ${current.fMD};
            display: flex;
            justify-content: space-between;
            font-wight: bold;
            ${current.secondaryFont};
        }
        > span {
            margin-bottom: ${current.gMD};
            display: flex;
            justify-content: space-between;

            > span {
                ${current.secondaryFont};
            margin-left: ${current.gSM};
            }
        }
    `;
  }}
`;
