import React from "react";
import styled from "styled-components";
import { Menu } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardIcon from "@mui/icons-material/Dashboard";

import { AuthProvider } from "../../server-requests/authContextProvider";
import { SignOutUser } from "../../firebase";
import { getError, shortName } from "./Utility";
import UseButton from "./useButton";
import UseDialog from "./useDialog";

const UseUserInformation = ({ isWhite }) => {
  const navigate = useNavigate();
  const state = useLocation();
  const dialogRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setAuthLoading } = React.useContext(AuthProvider);
  const { firebaseUser, isVerified, snackbar } = React.useContext(AuthProvider);

  const profileClickHandler = () => navigate("/profile");
  const dashboardClickHandler = () => navigate("/dashboard");

  const handleClick = React.useCallback(
    (event) => setAnchorEl(event.currentTarget),
    []
  );
  const handleClose = React.useCallback(() => setAnchorEl(null), []);
  const userShortName = shortName(
    firebaseUser && firebaseUser.displayName
      ? firebaseUser.displayName
      : firebaseUser.email
  );

  let profileButtonElement = null;
  if (firebaseUser && isVerified) {
    if (state.pathname === "/profile") {
      profileButtonElement = (
        <li>
          <UseButton
            clicked={dashboardClickHandler}
            value="Página inicial"
            type="button"
            skin="primary"
            size="md"
            display="full"
            icons={{ icon: <DashboardIcon /> }}
          />
        </li>
      );
    } else {
      profileButtonElement = (
        <li>
          <UseButton
            clicked={profileClickHandler}
            value="Perfil"
            type="button"
            skin="primary"
            size="md"
            display="full"
            icons={{ icon: <PersonIcon /> }}
          />
        </li>
      );
    }
  }

  const SignOutHandler = () => {
    SignOutUser()
      .then(() => setAuthLoading(true))
      .catch((status) => snackbar(getError(status)));
  };
  return (
    <>
      <UserInformationStyled
        aria-controls="userInformation"
        onClick={handleClick}
        isWhite={isWhite}
      >
        {userShortName}
      </UserInformationStyled>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <li>
          <span className="userNameBig">{userShortName}</span>
          <span className="accountName">
            {firebaseUser && firebaseUser.displayName}
          </span>
          <span>{firebaseUser && firebaseUser.email}</span>
        </li>
        {profileButtonElement}
        <li>
          <UseButton
            clicked={()=>{
              handleClose()
              dialogRef.current.Open()
            }}
            value="Sair"
            type="button"
            skin="danger"
            size="md"
            display="full"
            icons={{ icon: <ExitToAppIcon /> }}
          />
        </li>
      </StyledMenu>
      <UseDialog ref={dialogRef} title="Confirmar saída?" size="sm">
      <div className="body alert">
          Tem certeza de que deseja sair?
        </div>
        <div className="footer">
        <UseButton
          clicked={SignOutHandler}
          value="Sair"
          type="button"
          skin="danger"
          size="md"
          icons={{ icon: <ExitToAppIcon /> }}
          style={{marginLeft:'auto'}}
        />
        </div>
      </UseDialog>
    </>
  );
};

export default UseUserInformation;
const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))`
  ${(props) => {
    let theme = props.theme;
    let border = `1px solid rgba(${theme.black}, 0.1)`;
    return `
    .MuiMenu-paper {
      width: 250px;
      border: ${border};
      .MuiMenu-list{
        padding: 0!important;
        >li:first-child{
          display:flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .sizeXXL{
            font-size:80px;
            color:rgba(${theme.black}, 0.3);
            margin-bottom:${theme.gSM};
          }
          .accountName{
            color:rgb(${theme.primary});
            font-size:20px;
            font-weight:bold;
          }
          .userNameBig{
                ${theme.fXXL};
                width:70px;
                height:70px;
                border-radius:50%;
                background:rgb(${theme.secondary});
                color:rgb(${theme.white});
                align-items: center;
                display: flex;
                justify-content:center;
                margin-top:20px;
                margin-bottom:20px;
          }
          >span{
            margin-bottom:${theme.gSM};
            width:100%;
            display:flex;
            justify-content: center;
            align-items: center;
            word-break: break-all;
            text-align: center;
          }
        }
        >li{
          padding:${theme.gMD};
        }
        .profileHolder{
          border-top:${border};
          border-bottom:${border};
        }
      }
    }
  `;
  }}
`;
const UserInformationStyled = styled.div`
  ${(props) => {
    let theme = props.theme;

    let background = `rgb(${theme.white})`;
    if (props.isWhite) background = `rgb(${theme.primary})`;

    let borderColor = `1px solid rgb(${theme.white})`;
    if (props.isWhite) borderColor = `1px solid rgb(${theme.primary})`;

    let color = `rgb(${theme.primary})`;
    if (props.isWhite) color = `rgb(${theme.white})`;

    return `
            align-items: center;
            display: flex;
            cursor:pointer;
            ${theme.fontSecondary};
            justify-content:center;
            width:34px;
            height:34px;
            border-radius:50%;

            background:${background};
            border:${borderColor};
            color:${color};
            ${theme.fMD};
        `;
  }}
`;
